import { CustomLink } from "@/components/customComponents";
import { TERMS_CONDITIONS_URL, USER_AGREEMENT_URL } from "@/constants";
import { Checkbox, DatePicker, Form, FormInstance } from "antd";
import { FC } from "react";

interface Props {
  form: FormInstance;
}

const dotClass =
  "before:absolute before:left-[-20px] before:top-[9px] before:w-[5px] before:h-[5px] before:bg-[#00ADAE] before:rounded-full before:content-[''] before:align-middle before:mr-[10px]";

const Step4: FC<Props> = ({ form }) => {
  return (
    <div className="text-[#999]">
      <div className="px-32 mb-[108px]">
        <div className={`relative ${dotClass}`}>
          By checking this box, you agree to the{" "}
          <CustomLink href="https://stripe.com/legal/connect-account/recipient">
            terms and conditions of Stripe payment processing
          </CustomLink>
          .
        </div>
        <div className={`relative ${dotClass}`}>
          By checking this box, you agree to Leyline Technologies, Inc.{" "}
          <CustomLink href={USER_AGREEMENT_URL}>User Agreement.</CustomLink>
        </div>
        <div className={`relative ${dotClass}`}>
          By checking this box, you agree to the{" "}
          <CustomLink href={TERMS_CONDITIONS_URL}>
            Terms and Conditions
          </CustomLink>
          .
        </div>
        <div className={`relative ${dotClass}`}>
          By checking this box, you agree to the Leyline Service Fee of 10% of
          the total transaction value for services rendered, to be assessed at
          each payment processing.
        </div>
      </div>
      <Form form={form}>
        <Form.Item
          label=""
          name="isAgree"
          valuePropName="checked"
          className="text-center !mb-0"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Please carefully read the above content and agree.",
                      ),
                    ),
            },
          ]}
        >
          <Checkbox className="text-[#999]">Yes, I understand.</Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step4;
