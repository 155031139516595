import { Divider, Typography } from "antd";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

const { Text } = Typography;

import {
  PaymentMethodValueType,
  paymentMethodMap,
} from "@/components/businessComponents";
import {
  CustomLink,
  CustomModalV2,
  CustomSegmented,
  FileIcon,
} from "@/components/customComponents";
import { ApplicationStatEnum } from "@/pages/Applications/enum";
import { contractStepOptions } from "@/pages/jobs/components/signContractModal/utils";
import { ApplicantCardProps } from "@/pages/jobs/type";
import { padLeftImgUrl } from "@/utils/common";
import {
  formatToLongStyleUTC,
  formatToMiddleStyle,
  getTimezoneText,
} from "../../utils/date";
import styles from "./index.module.css";

type ModalProps = {
  curRole: "partyA" | "partyB";
  application: ApplicantCardProps;
  open: boolean;
  tipsContent?: ReactNode;
  refreshList: () => void;
  setOpen: (x: boolean) => void;
  onRevokeContract?: () => void;
};

const FieldRow = ({ label, value }: { label: string; value?: ReactNode }) => {
  return (
    <div className="flex py-[10px] mb-[10px]">
      <span className=" shrink-0 w-2/5 text-right mr-5">{label}:</span>
      <span>{value}</span>
    </div>
  );
};

const FileCard = ({ filePath }: { filePath?: string }) => {
  const fileName = filePath?.split("/").pop();
  return filePath ? (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      className="w-[330px] rounded-sm bg-[#2A2D30] py-6 px-4 flex items-center cursor-pointer hover:bg-white/[0.01]"
      onClick={() => window.open(padLeftImgUrl(filePath), "_blank")}
    >
      <FileIcon className="shrink-0" />
      <Text className="ml-4" ellipsis>
        {fileName}
      </Text>
    </div>
  ) : (
    "No data"
  );
};

export const JobContractDetailModal = ({
  curRole,
  application,
  open,
  tipsContent,
  refreshList,
  setOpen,
  onRevokeContract,
}: ModalProps) => {
  const [stepVal, setStepVal] = useState(1);

  // When opened, get the latest contract data
  useEffect(() => {
    if (open) refreshList();
    else setStepVal(1);
  }, [open, refreshList]);

  const status = application?.status;
  const {
    start_date,
    end_date,
    payment_method,
    headcount,
    currency,
    rate,
    timezone,
    total_cost,
    count,
    count_unit,
    service_fee_rate,
    service_fee,
    receive_amount,
    is_deposit_required,
    deposit,
    deposit_due_date,
    project_file_urls,
    delivery_and_payment_schedule_file_urls,
    contract_file_urls,
  } = application?.contract || {};

  const showTipText = useMemo(() => {
    return [
      ApplicationStatEnum.CONTRACTING,
      ApplicationStatEnum.A_SIGNED,
      ApplicationStatEnum.B_SIGNED,
      ApplicationStatEnum.COMPLETED,
    ].includes(status);
  }, [status]);

  const showVoidContract = useMemo(() => {
    return (
      curRole === "partyA" &&
      [ApplicationStatEnum.CONTRACTING, ApplicationStatEnum.A_SIGNED].includes(
        status,
      )
    );
  }, [status, curRole]);

  return (
    <CustomModalV2
      title="Contract details"
      width={1100}
      // classNames={{
      //   body: "max-h-[60vh] overflow-auto",
      // }}
      open={open}
      onOk={() => {
        setOpen(false);
      }}
      onCancel={() => {
        setOpen(false);
      }}
      footer={false}
    >
      {open && (
        <div className={styles.contractDetail}>
          {showTipText && (
            <div className="rounded-xl bg-white/[.03] py-4 px-7 text-center mx-5">
              <div className={`relative min-h-5 ${styles.tipsContent}`}>
                {tipsContent}
              </div>
              {showVoidContract && (
                <div className="text-[#999]">
                  Do we need to modify the contract content or cancel the
                  signing?{" "}
                  <CustomLink
                    showUnderline
                    onClick={() => onRevokeContract?.()}
                  >
                    Void Contract
                  </CustomLink>
                </div>
              )}
            </div>
          )}
          <div className="text-center pt-5 pb-7">
            <CustomSegmented
              value={stepVal}
              options={contractStepOptions}
              onChange={(val) => setStepVal(val as number)}
            />
          </div>

          <div className={stepVal === 1 ? "block" : "hidden"}>
            <div className="rounded-xl bg-white/[.04] pt-7 pb-5 mb-2">
              <FieldRow label="Project" value={application.project_name} />
              <FieldRow
                label="Job Title"
                value={application?.job_title || application?.extra?.title}
              />
              <FieldRow
                label="Party A"
                value={application?.contract?.party_a_name}
              />
              <FieldRow
                label="Party A’s Email"
                value={application?.contract?.party_a_email}
              />
            </div>
            <div className="rounded-xl bg-white/[.04] pt-7 pb-5 mb-2">
              <FieldRow
                label="Party B"
                value={application?.contract?.party_b_name}
              />
              <FieldRow
                label="Party B’s Email"
                value={application?.contract?.party_b_email}
              />
            </div>
            <div className="rounded-xl bg-white/[.04] pt-7 pb-5 mb-2">
              <FieldRow
                label="Timezone"
                value={getTimezoneText(timezone) ?? "--"}
              />
              <FieldRow
                label="Work Time Period"
                value={`${formatToLongStyleUTC(
                  start_date as string,
                )} to ${formatToLongStyleUTC(end_date as string)}`}
              />
            </div>
          </div>

          <div className={stepVal === 2 ? "block" : "hidden"}>
            <Divider className="!mt-0">Quote breakdown</Divider>
            <div className="rounded-xl bg-white/[.04] pt-7 pb-5 mb-2">
              <FieldRow
                label="Payment Method"
                value={
                  paymentMethodMap[payment_method as PaymentMethodValueType]
                }
              />
              <FieldRow label="Headcount" value={headcount?.toString()} />
              {payment_method === "Negotiable" ? (
                <>
                  <FieldRow
                    label="Total"
                    value={
                      <span className="text-[#00ADAE]">
                        {currency} {total_cost?.toFixed(2)}
                      </span>
                    }
                  />
                </>
              ) : (
                <>
                  <FieldRow
                    label="Unit price"
                    value={
                      <span className="text-[#00ADAE]">
                        {currency} {rate?.toFixed(2)}
                      </span>
                    }
                  />
                  <FieldRow
                    label={
                      payment_method === "Time-based"
                        ? "Duration (Per Person)"
                        : "Quantity (Total)"
                    }
                    value={
                      <span className="text-[#00ADAE]">
                        {count} {count_unit}
                      </span>
                    }
                  />
                  <FieldRow
                    label="Total"
                    value={
                      <div>
                        <div className="text-[#00ADAE]">
                          {currency} {total_cost?.toFixed(2)}
                        </div>
                        <div className="text-white/[.5]">
                          Total Cost ={" "}
                          {payment_method === "Time-based"
                            ? "Headcount * Duration"
                            : "Quantity"}{" "}
                          * Unit Price
                        </div>
                      </div>
                    }
                  />
                </>
              )}
              {curRole === "partyB" && (
                <>
                  <FieldRow
                    label="Service Fee"
                    value={
                      <div>
                        <div className="text-white/[.5]">
                          {currency} -{service_fee?.toFixed(2)}
                        </div>
                        <div className="text-white/[.5]">
                          {`${(service_fee_rate || 0.1) * 100}%`} Service Fee
                        </div>
                      </div>
                    }
                  />
                  <FieldRow
                    label="You’ll Receive"
                    value={
                      <span className="text-[#00ADAE]">
                        {currency} {receive_amount?.toFixed(2)}
                      </span>
                    }
                  />
                </>
              )}
            </div>
            <Divider>Deposit</Divider>
            <div className="rounded-xl bg-white/[.04] pt-7 pb-5 mb-2">
              <FieldRow
                label="Deposit"
                value={
                  is_deposit_required ? (
                    <span className="text-[#00ADAE]">
                      {currency} {deposit?.toFixed(2)}
                    </span>
                  ) : (
                    "No deposit required"
                  )
                }
              />
              {is_deposit_required && (
                <FieldRow
                  label="Please pay the deposit by"
                  value={formatToMiddleStyle(deposit_due_date)}
                />
              )}
            </div>
            <Divider>Settlement and payment cycle</Divider>
            <div className="rounded-xl bg-white/[.04] pt-7 pb-7 text-center">
              Please make the payment within 5 business days of confirming the
              settlement invoice.
            </div>
          </div>

          <div className={stepVal === 3 ? "block" : "hidden"}>
            <FieldRow
              label="Standard Project Files"
              value={<FileCard filePath={project_file_urls?.[0]} />}
            />
            <FieldRow
              label="Delivery and Payment Schedule"
              value={
                <FileCard
                  filePath={delivery_and_payment_schedule_file_urls?.[0]}
                />
              }
            />
            <FieldRow
              label="Other Supplementary Contracts"
              value={<FileCard filePath={contract_file_urls?.[0]} />}
            />
          </div>
        </div>
      )}
    </CustomModalV2>
  );
};
