import { Edit, SaveButton, useForm } from "@refinedev/antd";
import {
  IResourceComponentsProps,
  useBack,
  useInvalidate,
  useTranslate,
} from "@refinedev/core";
import { Button, Col, Flex, Form, Row, Steps } from "antd";
import { Divider, Space, Typography, Upload, message } from "antd";
import React, { useMemo, useRef } from "react";
import { ProfileForm } from "../../components/users/ProfileForm";
import { ProjectExperienceForm } from "../../components/users/ProjectExperienceForm";
import { WorkExperienceForm } from "../../components/users/WorkExperienceForm";
import { WorkPreferenceForm } from "../../components/users/WorkPreferenceForm";
import {
  IProfile,
  IProjectExperience,
  IWorkExperience,
  IWorkPreference,
} from "../../interfaces";

import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { UserType } from "@/__generated__/graphql";
import { useGlobalStore } from "@/stores";
import { convertFileListToUrlList, urlToFileList } from "@/utils/upload";
import { useDocumentTitle } from "@refinedev/react-router-v6";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectPreviewCard } from "../../components/users/components/ProjectPreviewCard";
import { WorkExpPreviewCard } from "../../components/users/components/WorkExpPreviewCard";
import { useRefreshToken } from "../../hooks";
import { IUserInfo } from "../../interfaces";
import {
  convertUploadListToId,
  makeUploadFileObject,
} from "../../utils/common";
import { autoFill, onAvatarPreview } from "../../utils/dev";
import { axiosInstance } from "../../utils/http";

const { Step } = Steps;

const formatInitialValues = (initialValues: IUserInfo | undefined) => {
  if (!initialValues) return initialValues;
  return {
    ...initialValues,
    profile: {
      ...initialValues.profile,
      avatar_url: urlToFileList(initialValues.profile.avatar_url),
      // page_cover_url: urlToFileList(initialValues.profile.page_cover_url),
    },
    project_experience: initialValues.project_experience.map((project) => ({
      ...project,
      project_duration: [
        project.start_date ? dayjs(project.start_date) : null,
        project.end_date ? dayjs(project.end_date) : null,
      ],
    })),
    work_experience: initialValues.work_experience.map((work) => ({
      ...work,
      start_date: work.start_date ? dayjs(work.start_date) : null,
      end_date: work.end_date ? dayjs(work.end_date) : null,
      work_duration: work.is_current
        ? null
        : [
            work.start_date ? dayjs(work.start_date) : null,
            work.end_date ? dayjs(work.end_date) : null,
          ],
    })),
  };
};

export const UserEditV2: React.FC<IResourceComponentsProps> = () => {
  useDocumentTitle("Edit Profile | LeyLine");
  const { currentRole } = useGlobalStore((state) => state);
  const back = useBack();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { fromPage } = state || {};

  const { formProps, queryResult } = useForm<IProfile>({
    resource: "users",
    id: "profile",
    warnWhenUnsavedChanges: false,
    queryOptions: {
      staleTime: 0,
    },
  });

  const [workPreferenceForm] = Form.useForm<IWorkPreference>();
  const [projectExpForm] = Form.useForm<IProjectExperience>();
  const [workExpForm] = Form.useForm<IWorkPreference>();

  const profileFormRef = useRef(null);
  const workPreferenceFormRef = useRef(null);
  const projectExperienceFormRef = useRef(null);
  const workExperienceFormRef = useRef(null);
  const [showProjectExpForm, setShowProjectExpForm] = useState(false);
  const [showWorkExpForm, setShowWorkExpForm] = useState(false);

  // biome-ignore lint:
  const scrollToRef = (ref: any) =>
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });

  const formattedInitialValues = useMemo(
    () => formatInitialValues(formProps?.initialValues as IUserInfo),
    [formProps?.initialValues],
  );

  // biome-ignore lint:
  useEffect(() => {
    if (currentRole === UserType.Company) {
      navigate("/companies/edit-homepage");
    }
  }, [currentRole]);

  useEffect(() => {
    workPreferenceForm.setFieldsValue(
      formattedInitialValues?.work_preference as IWorkPreference,
    );
  }, [workPreferenceForm, formattedInitialValues?.work_preference]);

  const [selectedProjectExp, setSelectedProjectExp] =
    useState<IProjectExperience | null>(null);
  const [selectedWorkExp, setSelectedWorkExp] =
    useState<IWorkExperience | null>();
  const [currentStep, setCurrentStep] = useState(0);

  const { refreshToken } = useRefreshToken();
  const invalidate = useInvalidate();
  const useRefresh = () => {
    const refresh = () => {
      invalidate({
        resource: "users",
        invalidates: ["all"],
      });
    };
    return refresh;
  };
  const refresh = useRefresh();

  // useEffect(() => {
  //   console.log("formProps", formProps);
  //   console.log("queryResult", queryResult);
  // }, [formProps, queryResult]);

  useEffect(() => {
    if (showProjectExpForm === false && selectedProjectExp) {
      projectExpForm?.resetFields();
    }
  }, [showProjectExpForm, projectExpForm, selectedProjectExp]);

  useEffect(() => {
    if (showWorkExpForm === false && selectedWorkExp) {
      workExpForm?.resetFields();
    }
  }, [showWorkExpForm, workExpForm, selectedWorkExp]);

  // biome-ignore lint:
  const onFinishProfile = async (formValues: any) => {
    const values = { ...formValues };
    try {
      if (values.avatar_url && Array.isArray(values.avatar_url)) {
        values.avatar_url =
          convertFileListToUrlList(values.avatar_url)?.[0] ?? null;
      }
      if (values.page_cover_url && Array.isArray(values.page_cover_url)) {
        values.page_cover_url = convertFileListToUrlList(
          values.page_cover_url,
        )?.[0];
      }
      await axiosInstance.put("/users/profile", values);
      await refreshToken();
      refresh();
      message.success("Account profile updated successfully");
    } catch (err) {
      message.error(
        // @ts-ignore
        `Failed to update profile. ${err?.errors?.detail}`,
      );
    }
  };
  // biome-ignore lint:
  const onFinishWorkPreference = async (values: any) => {
    try {
      await axiosInstance.put("/users/work-preference", values);
      // If there is unsaved data in the user profile, it will be lost after refreshing.
      // refresh();
      message.success("Profile updated successfully");
    } catch (err) {
      message.error(
        // @ts-ignore
        `Failed to update profile. ${err?.errors?.detail}`,
      );
    }
  };
  // biome-ignore lint:
  const onFinishProjectExp = async (values: any) => {
    const [startDate, endDate] = values.project_duration || [];
    const formattedValues = {
      ...values,
      id: selectedProjectExp?.id,
      start_date: startDate?.format("YYYY-MM-DD HH:mm:ss"),
      end_date: endDate?.format("YYYY-MM-DD HH:mm:ss"),
      cover_url: convertUploadListToId(values.cover_url),
    };

    formattedValues.project_duration = undefined;

    try {
      if (!formattedValues.id) {
        await axiosInstance.post("/users/project-exp", formattedValues);
      } else {
        await axiosInstance.put(
          `/users/project-exp/${formattedValues.id}`,
          formattedValues,
        );
      }
      setShowProjectExpForm(false);
      projectExpForm?.resetFields();
      refresh();
      message.success("Project Experience saved successfully");
    } catch (err) {
      message.error(
        // @ts-ignore
        `Failed to save project experience. ${err?.errors?.detail}`,
      );
    }
  };
  // biome-ignore lint:
  const onFinishWorkExperience = async (values: any) => {
    let formattedValues = {};
    if (values.is_current) {
      formattedValues = {
        ...values,
        id: selectedWorkExp?.id,
        start_date: values.start_date?.format("YYYY-MM-DD HH:mm:ss"),
        end_date: null,
        is_current: 1,
      };
    } else {
      const [startDate, endDate] = values.work_duration || [];
      formattedValues = {
        ...values,
        id: selectedWorkExp?.id,
        start_date: startDate?.format("YYYY-MM-DD HH:mm:ss"),
        end_date: endDate?.format("YYYY-MM-DD HH:mm:ss"),
        is_current: 0,
      };
    }
    // @ts-ignore
    formattedValues.work_duration = undefined;

    try {
      //@ts-ignore
      if (!formattedValues?.id) {
        await axiosInstance.post("/users/work-exp", formattedValues);
      } else {
        await axiosInstance.put(
          //@ts-ignore
          `/users/work-exp/${formattedValues.id}`,
          formattedValues,
        );
      }
      workExpForm?.resetFields();
      setShowWorkExpForm(false);
      refresh();
      message.success("Work experience saved successfully");
      console.log("Success:", values);
    } catch (err) {
      // @ts-ignore
      message.error(`Failed to save work experience. ${err?.errors?.detail}`);
    }
  };

  // biome-ignore lint:
  const onFinishFailed = (e: any) => {
    console.error("Finish Failed:", e);
  };
  // biome-ignore lint:
  const onValuesChange = (changedValues: any, allValues: any) => {
    console.log("valuesChanges", changedValues);
  };
  const formHeaderButtons = <></>;

  const onDeleteProjectExp = async (id: string) => {
    console.log("Delete Project Experience", id);
    await axiosInstance.delete(`/users/project-exp/${id}`).catch((err) => {
      message.error("Project experience deletion failed");
    });
    message.success("Project experience saved successfully");
    refresh();
  };
  const onEditProjectExp = (id: string) => {
    setShowProjectExpForm(true);
    const selectedValue = formattedInitialValues?.project_experience.find(
      (project) => project.id === id,
    );
    //@ts-ignore
    projectExpForm.setFieldsValue(selectedValue);
    //@ts-ignore
    setSelectedProjectExp(selectedValue);
    console.log("Edit Project Experience", selectedValue);
  };

  const onDeleteWorkExp = async (id: string) => {
    console.log("Delete Work Experience", id);
    await axiosInstance.delete(`/users/work-exp/${id}`).catch((err) => {
      message.error("Work Experience deleted failed");
    });
    message.success("Work Experience saved successfully");
    refresh();
  };

  const onEditWorkExp = (id: string) => {
    setShowWorkExpForm(true);
    const selectedValue = formattedInitialValues?.work_experience.find(
      (item) => item.id === id,
    );
    //@ts-ignore
    workExpForm.setFieldsValue(selectedValue);
    //@ts-ignore
    setSelectedWorkExp(selectedValue);
    console.log("Edit Project Experience", id);
  };

  return (
    <Edit
      isLoading={queryResult?.isLoading}
      headerButtons={formHeaderButtons}
      footerButtons={formHeaderButtons}
      headerProps={{
        onBack: () => {
          fromPage === "noviceGuide" ? navigate("/homepage") : back();
        },
      }}
      title={"Edit Profile"}
    >
      <Row gutter={24}>
        <Col span={4}>
          <Steps
            direction="vertical"
            current={currentStep}
            className="mt-4"
            progressDot
          >
            <Step
              title="Profile"
              onClick={() => {
                scrollToRef(profileFormRef);
                setCurrentStep(0);
              }}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Work Preference"
              onClick={() => {
                scrollToRef(workPreferenceFormRef);
                setCurrentStep(1);
              }}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Project Experience"
              onClick={() => {
                scrollToRef(projectExperienceFormRef);
                setCurrentStep(2);
              }}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Work Experience"
              onClick={() => {
                scrollToRef(workExperienceFormRef);
                setCurrentStep(3);
              }}
              style={{ cursor: "pointer" }}
            />
          </Steps>
        </Col>
        <Col offset={2} span={18}>
          <Divider orientation="left" orientationMargin="">
            Profile
          </Divider>
          <div ref={profileFormRef}>
            <Form
              // {...formProps}
              form={formProps?.form}
              layout="vertical"
              style={{ marginTop: 30 }}
              onFinish={onFinishProfile}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              // @ts-ignore
              initialValues={formattedInitialValues?.profile}
            >
              <ProfileForm
                formProps={formProps}
                onAvatarPreview={onAvatarPreview}
                showSaveButton
              />
            </Form>
          </div>

          <Divider orientation="left" orientationMargin="0">
            Work Preference
          </Divider>
          <div ref={workPreferenceFormRef}>
            <Form
              // {...formProps}
              form={workPreferenceForm}
              layout="vertical"
              style={{ marginTop: 30 }}
              onFinish={onFinishWorkPreference}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              initialValues={formattedInitialValues?.work_preference}
            >
              <WorkPreferenceForm
                form={workPreferenceForm}
                formProps={formProps}
                onAvatarPreview={onAvatarPreview}
              />
              <Row>
                <Space>
                  <SaveButton
                    style={{ color: "white" }}
                    type="primary"
                    htmlType="submit"
                  />
                </Space>
              </Row>
            </Form>
          </div>

          <Divider orientation="left" orientationMargin="0">
            Project Experience
          </Divider>
          <div ref={projectExperienceFormRef}>
            {!showProjectExpForm && (
              <Button
                type="primary"
                onClick={() => {
                  setSelectedProjectExp(null);
                  setShowProjectExpForm(true);
                }}
              >
                + Add Project Experience
              </Button>
            )}

            {/* Conditionally render the Project Experience Form based on showProjectExpForm state */}
            {showProjectExpForm && (
              <Form
                form={projectExpForm}
                layout="vertical"
                style={{ marginTop: 30 }}
                onFinish={onFinishProjectExp}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
              >
                <ProjectExperienceForm
                  formProps={formProps}
                  onAvatarPreview={onAvatarPreview}
                />
                <Row>
                  <Space>
                    <Button
                      type="default"
                      onClick={() => {
                        setShowProjectExpForm(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <SaveButton
                      style={{ color: "white" }}
                      type="primary"
                      htmlType="submit"
                    />
                  </Space>
                </Row>
              </Form>
            )}

            <Row className="mt-4">
              <Col span={12}>
                {formattedInitialValues?.project_experience.map((item) => {
                  // @ts-ignore
                  return (
                    <ProjectPreviewCard
                      //@ts-ignore
                      project={item}
                      key={item.id}
                      onDelete={onDeleteProjectExp}
                      onEdit={onEditProjectExp}
                    />
                  );
                })}
              </Col>
            </Row>
          </div>
          <Divider orientation="left" orientationMargin="0">
            Work Experience
          </Divider>
          <div ref={workExperienceFormRef}>
            {!showWorkExpForm && (
              <Button
                type="primary"
                onClick={() => {
                  setSelectedWorkExp(null);
                  setShowWorkExpForm(true);
                }}
              >
                + Add Work Experience
              </Button>
            )}

            {/* Conditionally render the Project Experience Form based on showWorkExpForm state */}
            {showWorkExpForm && (
              <Form
                form={workExpForm}
                layout="vertical"
                style={{ marginTop: 30 }}
                onFinish={onFinishWorkExperience}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
              >
                <WorkExperienceForm
                  formProps={formProps}
                  onAvatarPreview={onAvatarPreview}
                />
                <Row>
                  <Space>
                    <Button
                      type="default"
                      onClick={() => {
                        setShowWorkExpForm(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <SaveButton
                      style={{ color: "white" }}
                      type="primary"
                      htmlType="submit"
                    />
                  </Space>
                </Row>
              </Form>
            )}
            <Row className="mt-4">
              <Col span={12}>
                {formattedInitialValues?.work_experience.map((item) => {
                  // @ts-ignore
                  return (
                    <WorkExpPreviewCard
                      //@ts-ignore
                      work={item}
                      key={item.id}
                      onDelete={onDeleteWorkExp}
                      onEdit={onEditWorkExp}
                    />
                  );
                })}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Edit>
  );
};
