import { useBatchSendMsgMutation } from "@/__generated__/graphql";
import { CustomTextArea, CustomUpload } from "@/components/customComponents";
import { useGlobalStore } from "@/stores";
import {
  getImageFormat,
  getImageSize,
  getVideoDuration,
} from "@/utils/imUtils";
import { CloudUploadOutlined, UserOutlined } from "@ant-design/icons";
import { Conversation } from "@tencentcloud/chat";
import {
  Avatar,
  Button,
  Checkbox,
  Image,
  Input,
  Modal,
  Upload,
  message,
} from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";

interface Props {
  visible: boolean;
  conversationList: Conversation[];
  onClose: () => void;
}

const BatchSendMsgModal: FC<Props> = ({
  visible,
  conversationList,
  onClose,
}) => {
  const [searchVal, setSearchVal] = useState<string>("");
  const [inputText, setInputText] = useState<string>("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [batchSendMsg] = useBatchSendMsgMutation();
  const { chatClient } = useGlobalStore((state) => state);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!visible) {
      setSelectedUsers([]);
      setSearchVal("");
      setFileList([]);
      setInputText("");
    }
  }, [visible]);

  const handledConvList = useMemo(() => {
    return conversationList.filter((x) =>
      searchVal
        ? x.userProfile.nick.toUpperCase().includes(searchVal.toUpperCase())
        : true,
    );
  }, [conversationList, searchVal]);

  const fnSelectAll = () => {
    const arr = handledConvList.map((x) => x.userProfile.userID);
    setSelectedUsers(arr);
  };
  const onSelectChange = (vals: string[]) => {
    setSelectedUsers(vals);
  };
  const fnClearSelected = () => {
    setSelectedUsers([]);
  };
  const onInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSelectedUsers([]);
    setSearchVal(e.target.value);
  };
  const onSubmit = async () => {
    setLoading(true);
    const msgBody = [];
    if (inputText) {
      if (/^\s*$/.test(inputText)) {
        setLoading(false);
        return message.error("Please enter valid content.");
      }
      msgBody.push(
        JSON.stringify({
          MsgType: "TIMTextElem",
          MsgContent: { Text: inputText.trim() },
        }),
      );
    }
    if (fileList.length > 0) {
      await Promise.all(
        fileList.map(async (file: UploadFile) => {
          if ((file.type as string).startsWith("image/")) {
            const [Width, Height] = await getImageSize(file);
            msgBody.push(
              JSON.stringify({
                MsgType: "TIMImageElem",
                MsgContent: {
                  UUID: file.uid,
                  ImageFormat: getImageFormat(file),
                  ImageInfoArray: [
                    {
                      Type: 1,
                      Size: file.size,
                      Width,
                      Height,
                      URL: file.url,
                    },
                    {
                      Type: 2,
                      Size: file.size,
                      Width,
                      Height,
                      URL: file.url,
                    },
                    {
                      Type: 3,
                      Size: file.size,
                      Width,
                      Height,
                      URL: file.url,
                    },
                  ],
                },
              }),
            );
          } else if ((file.type as string).startsWith("video/")) {
            const duration = await getVideoDuration(file);
            const extension = file.name.split(".").pop()?.toLowerCase();
            msgBody.push(
              JSON.stringify({
                MsgType: "TIMVideoFileElem",
                MsgContent: {
                  UUID: file.uid,
                  VideoUrl: file.url,
                  VideoSize: file.size,
                  VideoSecond: duration,
                  VideoFormat: extension,
                  VideoDownloadFlag: 2,
                },
              }),
            );
          } else {
            msgBody.push(
              JSON.stringify({
                MsgType: "TIMFileElem",
                MsgContent: {
                  UUID: file.uid,
                  URL: file.url,
                  FileSize: file.size,
                  FileName: file.name,
                  Download_Flag: 2,
                },
              }),
            );
          }
        }),
      );
    }
    Promise.all(
      msgBody.map((str) =>
        batchSendMsg({
          variables: {
            batchSendMsgIn: {
              toAccounts: selectedUsers,
              msgBody: [str],
              fromAccount: chatClient?.getLoginUser() as string,
              syncOtherMachine: 1,
              onlineOnly: 0,
            },
          },
        }),
      ),
    )
      .then(() => {
        message.success("Sent successfully.");
        onClose();
      })
      .catch((err) => {
        message.error(
          err.message || "An error has occurred. Please try again later.",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Bulk Send Messages"
      width={800}
      open={visible}
      okText="Send Message"
      okButtonProps={{
        disabled:
          selectedUsers.length === 0 || (!inputText && fileList.length === 0),
        loading,
      }}
      destroyOnClose
      onCancel={onClose}
      onOk={onSubmit}
    >
      <div className="flex">
        <div className="w-[340px] shrink-0 pr-3 mr-3 border-r-[1px] border-solid border-[#ddd]">
          <Input.Search
            placeholder="Search"
            allowClear
            className="mb-2"
            value={searchVal}
            onChange={onInputChange}
          />
          <div className="flex justify-between mb-1">
            <Button type="link" size="small" onClick={fnSelectAll}>
              Select All
            </Button>
            <div>
              <span>{selectedUsers.length} Selected</span>
              {selectedUsers.length > 0 && (
                <Button type="link" size="small" onClick={fnClearSelected}>
                  Clear selection
                </Button>
              )}
            </div>
          </div>
          <Checkbox.Group
            className="w-full max-h-[60vh] overflow-auto"
            value={selectedUsers}
            onChange={onSelectChange}
          >
            {handledConvList.map((x) => {
              let extraText = "";
              if (x.customData) {
                try {
                  const { pName, titl } = JSON.parse(x.customData).jInfo;
                  extraText = `${titl} | ${pName}`;
                } catch (error) {
                  console.error(error);
                }
              }
              return (
                <Checkbox
                  value={x.userProfile.userID}
                  key={x.conversationID}
                  className="mb-2"
                >
                  <div className="flex items-center">
                    <Avatar
                      size={36}
                      src={x.userProfile.avatar}
                      icon={<UserOutlined />}
                      className="mr-1"
                    />
                    <div>
                      <div className="font-bold text-base">
                        {x.userProfile.nick || x.userProfile.userID}
                      </div>
                      <div className="text-[#666] text-xs">{extraText}</div>
                    </div>
                  </div>
                </Checkbox>
              );
            })}
          </Checkbox.Group>
        </div>
        <div className="flex-1 overflow-hidden">
          <Input.TextArea
            value={inputText}
            placeholder="Please enter..."
            className="mb-4"
            showCount
            maxLength={10000}
            autoSize={{ minRows: 6, maxRows: 15 }}
            onChange={(e) => setInputText(e.target.value)}
          />
          <CustomUpload
            listType="text"
            multiple
            maxCount={5}
            limitSize={100}
            onChange={(e) => {
              console.log("upload change", e);
              setFileList(e);
            }}
          >
            <Button icon={<CloudUploadOutlined />}>Click to Upload</Button>
          </CustomUpload>
        </div>
      </div>
    </Modal>
  );
};

export default BatchSendMsgModal;
