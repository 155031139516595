import { ApplGroupEnum, ReceGroupEnum } from "@/pages/Applications/utils";
import { httpGet } from "@/utils/http";

export const getReceivedApplications = (params: {
  project_id?: string;
  only_open?: boolean;
  job_id?: string;
  published_list_status?: ReceGroupEnum;
  sub_status?: number;
  sort?: string;
}) =>
  httpGet("/applications/company/received", {
    params,
  });

export const getPublishedApplications = (params: {
  role?: string;
  publised_list_status?: ApplGroupEnum;
  sub_status?: number;
  search?: string;
  page?: number;
  size?: number;
}) =>
  httpGet("/applications/applicant/published", {
    params,
  });
