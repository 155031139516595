import { useStepsForm } from "@refinedev/antd";
import { useApiUrl, useUpdate } from "@refinedev/core";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Modal,
  Row,
  message,
  theme,
} from "antd";

import toast from "react-hot-toast";

import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { IUserProfile } from "../../interfaces";
import styles from "./styles/apply.module.css";
import { ApplyInfoType, transformToFormData } from "./utils";

import { UserType } from "@/__generated__/graphql";
import PaymentMethodForm from "@/components/businessComponents/paymentMethod";
import { CustomButton } from "@/components/customComponents";
import { useJobInfoContext } from "@/contexts/jobInfo";
import UploadOrInput from "@/pages/enterprises/components/UploadOrInput";
import { useGlobalStore } from "@/stores";
import { delFileUrlPrefix, modalConfirmSync } from "@/utils/common";

const { useToken } = theme;
interface ApplyJobProps {
  applyInfo?: ApplyInfoType;
  onSuccess: () => void;
}
interface FormValues {
  headcount: number;
  budget: {
    quantity: number;
    duration_per_ppl: string;
    unit: string;
    unit_price: number;
  };
  projectRepresentative: string;
  documentType: string;
  documentNumber: string;
  testLink?: string;
}

const ApplyJob = (
  { applyInfo, onSuccess }: ApplyJobProps,
  ref: Ref<unknown> | undefined,
) => {
  const apiUrl = useApiUrl();
  const isFormChanged = useRef(false);
  const jobInfo = useJobInfoContext();

  const { mutate, isLoading } = useUpdate({
    mutationOptions: {
      // @ts-ignore
      onError: false,
      onSuccess: () => false,
    },
  });

  useImperativeHandle(ref, () => ({
    checkFormChanged: () => isFormChanged.current,
  }));

  const { formProps } = useStepsForm<IUserProfile>({
    warnWhenUnsavedChanges: false,
  });
  const { form } = formProps;

  const [companyVerified, setCompanyVerified] = useState(false);
  const [pending, setPending] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const { currentRole } = useGlobalStore((state) => state);
  const [loading, setLoading] = useState(false);
  const paymentMethodFormRef = useRef();
  const { token } = useToken();
  const [modal, contextHolder] = Modal.useModal();

  const fetchCompanyInfo = async () => {
    const responseData = await fetch(`${apiUrl}/companies/my-company`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
      },
    });
    const data = await responseData.json();
    setCompanyVerified(data?.is_verified === true);
  };

  // biome-ignore lint:
  React.useEffect(() => {
    fetchCompanyInfo();
    if (applyInfo) {
      const parsedData = transformToFormData(applyInfo);
      setCurrency(applyInfo.currency);
      form?.setFieldsValue(parsedData);
    } else {
      const jobId = sessionStorage.getItem("currentJobIdToApply");
      (async () => {
        const loadingMessage = message.loading(
          "Fetching info, please wait...",
          0,
        );
        try {
          const response2 = await fetch(
            `${apiUrl}/applications/applicant/published?page=1&size=40`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
              },
            },
          );
          const data2 = await response2.json();
          console.log(data2); // @ts-ignore
          const appliedJobs = data2.items?.filter((x) => x.job_id === jobId);
          if (
            appliedJobs?.length > 0 &&
            appliedJobs[0].application_status === "PENDING"
          ) {
            toast("Your application is being processed", {
              icon: "⚠️",
            });
            setPending(true);
          }
        } catch (error) {
          console.error("Error fetching job detail", error);
          message.error("Failed to fetch job detail - try again later");
        } finally {
          loadingMessage();
        }
      })();
    }
  }, [applyInfo]);

  const onFinish = async (values: FormValues) => {
    try {
      const { testLink } = values;
      const testFile = form?.getFieldValue("testFile");
      const testType = form?.getFieldValue("testType");

      const jobId = sessionStorage.getItem("currentJobIdToApply");

      if (!jobId) {
        message.error("Failed to get the job info - try again later");
        return;
      }

      // const operateRes = await modalConfirmSync(
      //   {
      //     title: "Collection Account Registration Notice:",
      //     cancelText: "Cancel",
      //     okText: "I have registered my payment account",
      //     width: 470,
      //     content: (
      //       <div>
      //         <div>
      //           To ensure your rights, please register the appropriate payment
      //           account before applying for a job based on your situation:
      //         </div>
      //         <div className={styles.dot}>
      //           If the employer is a Chinese company and you are a Chinese
      //           citizen, please search for “身边云” via WeChat and enter the
      //           mini-program for further instructions.
      //         </div>
      //         <div className={styles.dot}>
      //           For other situations, please click the link below to register
      //           for a Deel contractor account:
      //         </div>
      //         <div className={`${styles.dot} pl-5`}>
      //           <a
      //             style={{ color: "#1677ff" }}
      //             href="https://demo.deel.com/signup"
      //             target="_blank"
      //             rel="noreferrer"
      //           >
      //             https://demo.deel.com/signup
      //           </a>
      //         </div>
      //         <span>
      //           If you have any questions, please contact customer support at:
      //           leyline@leylinepro.com.
      //         </span>
      //       </div>
      //     ),
      //   },
      //   modal,
      // );
      // if (!operateRes) return;
      const operateRes2 = await modalConfirmSync(
        {
          title: "Confirm",
          content: "Apply for this position?",
        },
        modal,
      );
      if (!operateRes2) return;
      const isPersonal = [UserType.Individual].includes(
        currentRole as UserType,
      );
      const requestBody = {
        project_id: jobInfo?.project_id,
        role: isPersonal ? "Individual" : "Company",
        // @ts-ignore
        ...paymentMethodFormRef.current.transformFormValues(),
        ...(testType === "upload"
          ? {
              answer_url:
                testFile?.length > 0
                  ? delFileUrlPrefix(
                      testFile[0].response?.id || testFile[0].url,
                    )
                  : "",
            }
          : null),
        ...(testType === "link" ? { answer_link: testLink } : null),
        currency,
      };
      console.log("Data to send: ", requestBody);
      const mutateCallback = {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        onError: (error: any) => {
          message.error(error.errors?.detail || "Create / Update fail");
          return false;
        },
        onSuccess: () => {
          isFormChanged.current = false;
          onSuccess();
          message.success(
            "Your application has been submitted and is waiting for processing.",
          );
          return false;
        },
      };
      if (applyInfo?.id) {
        mutate(
          {
            resource: "applications",
            id: `applicant/${applyInfo.id}`,
            meta: {
              method: "put",
            },
            values: requestBody,
          },
          mutateCallback,
        );
      } else {
        // @ts-ignore
        requestBody.job_id = jobId;
        mutate(
          {
            resource: "applications",
            id: "",
            meta: {
              method: "post",
            },
            values: requestBody,
          },
          mutateCallback,
        );
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to submit application");
    } finally {
      setLoading(false);
    }
  };

  // @ts-ignore
  const onFormFieldsChange = (fields) => {
    isFormChanged.current = true;
    if (fields[0].name[0] === "payment_method") {
      form?.setFieldValue(["budget", "unit"], "");
    }
  };

  const formContent = (
    <Form
      {...formProps}
      // initialValues={{
      //   payment_method: "Piece-rate",
      // }}
      name="applyJobForm"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      /* @ts-ignore */
      onFinish={onFinish}
      layout="horizontal"
      onFieldsChange={onFormFieldsChange}
    >
      <Form.Item label="Project">
        <span className="text-[#666]" style={{ color: token.colorTextBase }}>
          {jobInfo?.project_name}
        </span>
      </Form.Item>
      <Form.Item label="Job Title">
        <span style={{ color: token.colorTextBase }}>{jobInfo?.job_title}</span>
      </Form.Item>
      <PaymentMethodForm
        hideCNY
        ref={paymentMethodFormRef}
        form={form as FormInstance}
        currency={currency}
        setCurrency={setCurrency}
      />
      <UploadOrInput
        typeFieldName="testType"
        typeFieldLabel="Test"
        uploadProps={{
          // accept: "image/*,video/*",
          limitSize: 10,
          limitDesc: "The file should not exceed 10MB.",
        }}
        fileFieldName="testFile"
        linkFieldName="testLink"
      />
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("You must accept the agreement")),
          },
        ]}
        wrapperCol={{ offset: 7 }}
      >
        <Checkbox className="text-xs">
          By uploading onto the website you confirm that no third party
          Intellectual Property rights have been violated, and you are solely
          responsible for such infringements if any disputes arise from your
          uploaded files.
        </Checkbox>
      </Form.Item>
    </Form>
  );

  return (
    <Row gutter={24}>
      <Col span={24} className="overflow-auto">
        <div>
          {formContent}
          <div className="text-center pt-8">
            {pending ? (
              <CustomButton
                disabled
                type="primary"
                className="w-1/2"
                onClick={() => form?.submit()}
              >
                {applyInfo ? "Submit" : "Apply"} (not available)
              </CustomButton>
            ) : (
              <CustomButton
                type="primary"
                className="w-1/2"
                onClick={() => {
                  form?.submit();
                }}
                loading={isLoading}
              >
                {applyInfo ? "Submit" : "Apply"}
              </CustomButton>
            )}
          </div>
        </div>
        {contextHolder}
      </Col>
    </Row>
  );
};

export const ApplyJobAlternative = forwardRef(ApplyJob);
