import { padLeftImgUrl } from "@/utils/common";
import { isImageFile, isVideoFile } from "@/utils/upload";
import { PlusOutlined } from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import { ConfigProvider, Image, Upload, message } from "antd";
import { UploadProps } from "antd/es/upload";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { useContext, useEffect, useState } from "react";
import uploadImg from "/images/uploadClould.png";
import styles from "./styles/upload.module.css";

type OmitUploadProps = Omit<UploadProps, "onChange">;

interface LimitSize {
  video: number;
  image: number;
}
interface CustomUploadProps extends OmitUploadProps {
  onChange?: (e: UploadFile[]) => void;
  limitSize?: number | LimitSize;
}

const getFileType = (file: RcFile) => {
  if (file.type.startsWith("video/")) return "video";
  if (file.type.startsWith("image/")) return "image";
  return "other";
};

export const CustomUpload = ({
  fileList,
  onChange,
  limitSize = 10,
  ...props
}: CustomUploadProps) => {
  const apiUrl = useApiUrl();
  const [tempFileList, setTempFileList] = useState<UploadFile[]>([]);
  const themeConfig = useContext(ConfigProvider.ConfigContext);

  useEffect(() => {
    setTempFileList(fileList ?? []);
  }, [fileList]);

  const beforeUpload = (file: RcFile) => {
    let isOver = false;
    let overSize = limitSize;
    const sizeMB = file.size / 1024 / 1024;
    const fileType = getFileType(file);

    if (limitSize) {
      if (typeof limitSize === "number") {
        isOver = sizeMB > limitSize;
        overSize = limitSize;
      } else {
        isOver = ["video", "image"].includes(fileType)
          ? sizeMB > limitSize[fileType as "video" | "image"]
          : false;
        overSize = limitSize[fileType as "video" | "image"];
      }
    }
    if (isOver) {
      message.warning(`File must smaller than ${overSize}MB!`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const uploadButton = (
    <div
      className={
        props.listType === "picture"
          ? "w-full text-center cursor-pointer pt-3 pb-7 rounded-sm"
          : ""
      }
    >
      {props.listType === "picture" ? (
        <>
          <Image preview={false} width={52} height={52} src={uploadImg} />
          <div>Click to Upload</div>
        </>
      ) : (
        <>
          <PlusOutlined className="text-2xl" />
          <div className="mt-1">Upload</div>
        </>
      )}
    </div>
  );
  const previewFile = (file: UploadFile, isImage: boolean) => {
    if (!file || !file.originFileObj) return;
    const src = file.url || URL.createObjectURL(file.originFileObj as Blob);
    if (isImage) {
      const imgWindow = window.open(src);
      if (imgWindow) {
        imgWindow.document.write(`<img src="${src}" style="width: 100%;">`);
      }
    } else if (isVideoFile(file)) {
      const videoWindow = window.open(src);
      if (videoWindow) {
        videoWindow.document.write(
          `<video src="${src}" controls style="width: 100%;"></video>`,
        );
      }
    }
  };

  return (
    <ConfigProvider
      theme={
        {
          // token: {
          //   colorTextPlaceholder: "#686868",
          // },
        }
      }
    >
      <Upload
        rootClassName={
          // @ts-ignore
          themeConfig?.theme?.mode === "dark" ? styles.uploadWrapper : ""
        }
        action={`${apiUrl}/users/upload`}
        headers={{
          // biome-ignore lint:
          authorization: "Bearer " + localStorage.getItem("leyline-auth"),
        }}
        name="file"
        multiple={false}
        fileList={tempFileList}
        listType="picture-card"
        maxCount={1}
        beforeUpload={beforeUpload}
        onChange={({ fileList }) => {
          const newFileList = fileList.map((file) => {
            return {
              ...file,
              url: padLeftImgUrl(file.url ? file.url : file.response?.id),
            };
          });
          setTempFileList(newFileList);
          onChange?.(newFileList);
        }}
        onPreview={async (file) => {
          previewFile(file, isImageFile(file));
        }}
        {...props}
      >
        {props.children || uploadButton}
      </Upload>
    </ConfigProvider>
  );
};
