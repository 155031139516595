import PaymentMethod from "@/components/businessComponents/paymentMethod";
import {
  CustomInput,
  CustomInputNumber,
  CustomLink,
  CustomRadio,
  CustomSelect,
} from "@/components/customComponents";
import { USER_AGREEMENT_URL } from "@/constants";
import { generateTimezoneOptions } from "@/utils/date";
import {
  Checkbox,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  InputNumber,
  Switch,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { currencyOptions, paymentMethodOptions } from "./utils";

interface Props {
  form: FormInstance;
}

const Step2: FC<Props> = ({ form }) => {
  const [currency, setCurrency] = useState("USD");
  const paymentMethodFormRef = useRef();
  const isNeedDeposit = Form.useWatch("is_deposit_required", form);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().subtract(1, "day").endOf("day");
  };

  useEffect(() => {
    form.setFieldValue("currency", currency);
  }, [form, currency]);

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ is_deposit_required: true }}
      >
        <Divider className="!mt-0">Quote breakdown</Divider>
        <div className="bg-white/[.1] pt-10 pb-2 rounded-xl">
          <PaymentMethod
            hideCNY
            ref={paymentMethodFormRef}
            form={form}
            currency={currency}
            setCurrency={setCurrency}
          />
        </div>
        <Divider>Deposit</Divider>
        <div className="bg-white/[.1] pt-10 pb-2 rounded-xl">
          <Form.Item
            label="Deposit required?"
            name="is_deposit_required"
            required
            extra="It is recommended that both parties negotiate and decide."
          >
            <Switch />
          </Form.Item>
          {isNeedDeposit && (
            <>
              <Form.Item
                label="Deposit"
                name="depositAmount"
                extra="You may negotiate a deposit amount with your counterparty to kick-start the project, stripe fees apply."
                rules={[
                  { required: true },
                  {
                    type: "number",
                    min: 1,
                  },
                  {
                    type: "number",
                    max: 999999999,
                  },
                ]}
              >
                <CustomInputNumber
                  addonBefore={
                    <CustomSelect
                      value={currency}
                      onChange={setCurrency}
                      options={currencyOptions}
                      style={{ width: 140 }}
                    />
                  }
                  controls={false}
                  precision={2}
                  placeholder="Please Enter"
                />
              </Form.Item>
              <Form.Item
                label="Please pay the deposit by"
                name="deposit_due_date"
                // extra="Please pay the deposit by this date. If the deposit is not paid in time, your contract will be automatically void"
                rules={[{ required: true, message: "Please select a date" }]}
              >
                <DatePicker disabledDate={disabledDate} className="w-64" />
              </Form.Item>
            </>
          )}
        </div>
        <Divider>Settlement and payment cycle</Divider>
        <div className="bg-white/[.1] py-10 rounded-xl text-center mb-14">
          Please make payment within 5 business days of confirming the
          settlement invoice.
        </div>
        <Form.Item
          label=""
          name="isAgree"
          wrapperCol={{ span: 24 }}
          valuePropName="checked"
          className="!mb-0"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Please carefully read the user agreement."),
                    ),
            },
          ]}
        >
          <Checkbox className="text-[#999]">
            By checking this box, you agree to entrust Leyline Technologies,
            Inc. with the responsibility to facilitate payments via Stripe,
            including the collection and holding of deposits, and to manage any
            disputes related to the deposits in conformance with our{" "}
            <CustomLink href={USER_AGREEMENT_URL}>User Agreement</CustomLink>.
          </Checkbox>
        </Form.Item>
        <Form.Item name="currency" hidden />
      </Form>
    </div>
  );
};

export default Step2;
