import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Represents NULL values */
  Void: { input: any; output: any; }
};

export type BatchSendMsgIn = {
  fromAccount: Scalars['String']['input'];
  msgBody: Array<Scalars['String']['input']>;
  onlineOnly?: InputMaybe<Scalars['Int']['input']>;
  syncOtherMachine?: InputMaybe<Scalars['Int']['input']>;
  toAccounts: Array<Scalars['String']['input']>;
};

export type BatchSendMsgOut = {
  __typename?: 'BatchSendMsgOut';
  errorList?: Maybe<Array<ErrorItem>>;
  msgKey: Scalars['String']['output'];
};

export type ErrorItem = {
  __typename?: 'ErrorItem';
  errorCode: Scalars['Int']['output'];
  toAccount: Scalars['String']['output'];
};

export type GetUserSigIn = {
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  role: UserType;
};

export type GetUserSigOut = {
  __typename?: 'GetUserSigOut';
  imId: Scalars['String']['output'];
  userSig: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  batchSendMsg: BatchSendMsgOut;
  importImUser: GetUserSigOut;
  setCustomDataConversation?: Maybe<Scalars['Void']['output']>;
};


export type MutationBatchSendMsgArgs = {
  batchSendMsgIn: BatchSendMsgIn;
};


export type MutationImportImUserArgs = {
  getUserSigIn: GetUserSigIn;
};


export type MutationSetCustomDataConversationArgs = {
  setCustomDataConversationIn: SetCustomDataConversationIn;
};

export type Query = {
  __typename?: 'Query';
  placeholder: Scalars['String']['output'];
};

export type SetCustomDataConversationIn = {
  contactType?: InputMaybe<Scalars['Int']['input']>;
  customData: Scalars['String']['input'];
  fromAccount: Scalars['String']['input'];
  fromAccountRole?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  optType?: InputMaybe<Scalars['Int']['input']>;
  toAccount: Scalars['String']['input'];
};

export enum UserType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL'
}

export type ImportImUserMutationVariables = Exact<{
  getUserSigIn: GetUserSigIn;
}>;


export type ImportImUserMutation = { __typename?: 'Mutation', importImUser: { __typename?: 'GetUserSigOut', userSig: string, imId: string } };

export type SetRemoteCustomDataConversationMutationVariables = Exact<{
  setCustomDataIn: SetCustomDataConversationIn;
}>;


export type SetRemoteCustomDataConversationMutation = { __typename?: 'Mutation', setCustomDataConversation?: any | null };

export type BatchSendMsgMutationVariables = Exact<{
  batchSendMsgIn: BatchSendMsgIn;
}>;


export type BatchSendMsgMutation = { __typename?: 'Mutation', batchSendMsg: { __typename?: 'BatchSendMsgOut', msgKey: string, errorList?: Array<{ __typename?: 'ErrorItem', toAccount: string, errorCode: number }> | null } };


export const ImportImUserDocument = gql`
    mutation ImportImUser($getUserSigIn: GetUserSigIn!) {
  importImUser(getUserSigIn: $getUserSigIn) {
    userSig
    imId
  }
}
    `;
export type ImportImUserMutationFn = Apollo.MutationFunction<ImportImUserMutation, ImportImUserMutationVariables>;

/**
 * __useImportImUserMutation__
 *
 * To run a mutation, you first call `useImportImUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportImUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importImUserMutation, { data, loading, error }] = useImportImUserMutation({
 *   variables: {
 *      getUserSigIn: // value for 'getUserSigIn'
 *   },
 * });
 */
export function useImportImUserMutation(baseOptions?: Apollo.MutationHookOptions<ImportImUserMutation, ImportImUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportImUserMutation, ImportImUserMutationVariables>(ImportImUserDocument, options);
      }
export type ImportImUserMutationHookResult = ReturnType<typeof useImportImUserMutation>;
export type ImportImUserMutationResult = Apollo.MutationResult<ImportImUserMutation>;
export type ImportImUserMutationOptions = Apollo.BaseMutationOptions<ImportImUserMutation, ImportImUserMutationVariables>;
export const SetRemoteCustomDataConversationDocument = gql`
    mutation SetRemoteCustomDataConversation($setCustomDataIn: SetCustomDataConversationIn!) {
  setCustomDataConversation(setCustomDataConversationIn: $setCustomDataIn)
}
    `;
export type SetRemoteCustomDataConversationMutationFn = Apollo.MutationFunction<SetRemoteCustomDataConversationMutation, SetRemoteCustomDataConversationMutationVariables>;

/**
 * __useSetRemoteCustomDataConversationMutation__
 *
 * To run a mutation, you first call `useSetRemoteCustomDataConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRemoteCustomDataConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRemoteCustomDataConversationMutation, { data, loading, error }] = useSetRemoteCustomDataConversationMutation({
 *   variables: {
 *      setCustomDataIn: // value for 'setCustomDataIn'
 *   },
 * });
 */
export function useSetRemoteCustomDataConversationMutation(baseOptions?: Apollo.MutationHookOptions<SetRemoteCustomDataConversationMutation, SetRemoteCustomDataConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRemoteCustomDataConversationMutation, SetRemoteCustomDataConversationMutationVariables>(SetRemoteCustomDataConversationDocument, options);
      }
export type SetRemoteCustomDataConversationMutationHookResult = ReturnType<typeof useSetRemoteCustomDataConversationMutation>;
export type SetRemoteCustomDataConversationMutationResult = Apollo.MutationResult<SetRemoteCustomDataConversationMutation>;
export type SetRemoteCustomDataConversationMutationOptions = Apollo.BaseMutationOptions<SetRemoteCustomDataConversationMutation, SetRemoteCustomDataConversationMutationVariables>;
export const BatchSendMsgDocument = gql`
    mutation BatchSendMsg($batchSendMsgIn: BatchSendMsgIn!) {
  batchSendMsg(batchSendMsgIn: $batchSendMsgIn) {
    msgKey
    errorList {
      toAccount
      errorCode
    }
  }
}
    `;
export type BatchSendMsgMutationFn = Apollo.MutationFunction<BatchSendMsgMutation, BatchSendMsgMutationVariables>;

/**
 * __useBatchSendMsgMutation__
 *
 * To run a mutation, you first call `useBatchSendMsgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchSendMsgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchSendMsgMutation, { data, loading, error }] = useBatchSendMsgMutation({
 *   variables: {
 *      batchSendMsgIn: // value for 'batchSendMsgIn'
 *   },
 * });
 */
export function useBatchSendMsgMutation(baseOptions?: Apollo.MutationHookOptions<BatchSendMsgMutation, BatchSendMsgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchSendMsgMutation, BatchSendMsgMutationVariables>(BatchSendMsgDocument, options);
      }
export type BatchSendMsgMutationHookResult = ReturnType<typeof useBatchSendMsgMutation>;
export type BatchSendMsgMutationResult = Apollo.MutationResult<BatchSendMsgMutation>;
export type BatchSendMsgMutationOptions = Apollo.BaseMutationOptions<BatchSendMsgMutation, BatchSendMsgMutationVariables>;