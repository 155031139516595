import { CustomButton } from "@/components/customComponents/CustomButton";
import { ConfigProvider, Modal, ModalProps } from "antd";
import { ReactNode } from "react";
import styles from "./styles/modal.module.css";

interface SubModalProps extends ModalProps {
  subTitle?: string | ReactNode;
}
export const CustomModalV2 = ({
  title,
  children,
  subTitle,
  classNames,
  ...props
}: SubModalProps) => {
  const { body: bodyClassName, ...restClassNames } = classNames || {};
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: "#333538",
          },
        },
      }}
    >
      <Modal
        closable
        classNames={{
          ...restClassNames,
          content: "!pt-[60px] !pb-10",
          footer: props.footer
            ? "!text-center !mt-0 !pt-10 !px-4"
            : "!mt-0 !pt-10 !px-4",
        }}
        {...props}
        footer={
          props.footer ?? (
            <CustomButton
              type="primary"
              {...props.okButtonProps}
              onClick={props.onOk}
            >
              {props.okText || "Confirm"}
            </CustomButton>
          )
        }
        title=""
        wrapClassName={styles.modalWrap}
      >
        <div className="text-white text-3xl mb-5 text-center !px-4">
          {title}
        </div>
        {!!subTitle && (
          <div className="text-[#999] mb-[30px] text-center !px-4">
            {subTitle}
          </div>
        )}
        <div
          className={`max-h-[38vh] !px-4 ${bodyClassName}`}
          style={{ overflow: "overlay" }}
        >
          {children}
        </div>
      </Modal>
    </ConfigProvider>
  );
};
