import { PaymentMethodValueType } from "@/components/businessComponents";
import { CustomInputNumber, CustomSelect } from "@/components/customComponents";
import { currencyOptions } from "@/pages/jobs/components/signContractModal/utils";
import { getApplyConst } from "@/pages/jobs/services";
import { useGlobalStore } from "@/stores";
import {
  unitforPaperworkOptions,
  unitforTimeOptions,
} from "@/utils/selectionList";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Divider, Form, FormInstance, Image, InputNumber } from "antd";
import { FC, useEffect, useState } from "react";
import paySecurityImg from "/images/paySecurity.png";

interface Props {
  form: FormInstance;
  value?: string;
  paymentMethod: PaymentMethodValueType;
}

const FieldRow = ({
  label,
  labelDesc,
  value,
  valueClassName,
  hideBorder = false,
}: {
  label: string;
  labelDesc?: string;
  value: string;
  valueClassName?: string;
  hideBorder?: boolean;
}) => {
  return (
    <div
      className={`flex justify-between py-7 ${
        hideBorder ? "" : "border-b-[1px] border-solid border-white/[.2]"
      }`}
    >
      <div>
        <div className="text-lg mb-3">{label}</div>
        {!!labelDesc && <div className="text-[#999]">{labelDesc}</div>}
      </div>
      <div className={`text-[#00ADAE] mt-[1px] font-medium ${valueClassName}`}>
        {value}
      </div>
    </div>
  );
};

const Step2: FC<Props> = ({ value, paymentMethod, form }) => {
  const [currency, setCurrency] = useState("USD");
  const [calculateRateUnit, setCalculateRateUnit] = useState<
    undefined | string
  >();
  const [serviceFee, setServiceFee] = useState("0.00");
  const [realIncome, setRealIncome] = useState("0.00");
  const headcount = Form.useWatch("headcount", form);
  const quantity = Form.useWatch("quantity", form);
  const unit_price = Form.useWatch("unit_price", form);
  const duration = Form.useWatch("duration", form);
  const totalAmountEx = Form.useWatch("totalAmountEx", form) || "";
  const totalCost = Form.useWatch("totalCost", form);
  const { serviceFeeRate } = useGlobalStore((state) => state);

  useEffect(() => {
    if (paymentMethod === PaymentMethodValueType.Paperwork) {
      setCalculateRateUnit("piece");
    } else if (paymentMethod === PaymentMethodValueType.Time) {
      setCalculateRateUnit("hour");
    } else setCalculateRateUnit(undefined);
  }, [paymentMethod]);

  useEffect(() => {
    form.setFieldValue("unit", calculateRateUnit);
  }, [form, calculateRateUnit]);

  useEffect(() => {
    form.setFieldValue("currency", currency);
  }, [form, currency]);

  useEffect(() => {
    if (!paymentMethod) return;
    if (!unit_price) form?.setFieldValue("totalAmountEx", "0.00");

    if (paymentMethod === PaymentMethodValueType.Paperwork) {
      if (quantity && unit_price) {
        const price = parseInt(quantity) * parseFloat(unit_price);
        const tempServiceFee = (price * serviceFeeRate).toFixed(2);
        form?.setFieldValue("totalAmountEx", price.toFixed(2));
        setServiceFee(tempServiceFee);
        setRealIncome((price - parseFloat(tempServiceFee)).toFixed(2));
      } else {
        form?.setFieldValue("totalAmountEx", "0.00");
        setServiceFee("0.00");
        setRealIncome("0.00");
      }
    } else if (paymentMethod === PaymentMethodValueType.Time) {
      if (headcount && duration && unit_price) {
        const price =
          parseInt(headcount) * parseFloat(duration) * parseFloat(unit_price);
        const tempServiceFee = (price * serviceFeeRate).toFixed(2);
        form?.setFieldValue("totalAmountEx", price.toFixed(2));
        setServiceFee(tempServiceFee);
        setRealIncome((price - parseFloat(tempServiceFee)).toFixed(2));
      } else {
        form?.setFieldValue("totalAmountEx", "0.00");
        setServiceFee("0.00");
        setRealIncome("0.00");
      }
    } else {
      setServiceFee(
        totalCost ? (totalCost * serviceFeeRate).toFixed(2) : "0.00",
      );
      setRealIncome(
        totalCost ? (totalCost * (1 - serviceFeeRate)).toFixed(2) : "0.00",
      );
    }
  }, [
    paymentMethod,
    form,
    headcount,
    quantity,
    unit_price,
    duration,
    totalCost,
    serviceFeeRate,
  ]);

  const SelectCurrency = ({ field }: { field: string }) => (
    <CustomSelect
      value={currency}
      onChange={(e) => {
        setCurrency(e);
        form.validateFields([field]);
      }}
      options={currencyOptions}
      style={{ width: 140 }}
    />
  );
  const SelectUnit = ({
    field,
    options,
  }: { field: string; options: { label: string; value: string }[] }) => (
    <CustomSelect
      style={{ width: 140 }}
      placeholder="Select unit"
      options={options}
      value={calculateRateUnit}
      onChange={(v) => {
        form.validateFields([field]);
        setCalculateRateUnit(v);
      }}
    />
  );

  return (
    <div>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Divider className="!mt-0">Quote breakdown</Divider>
        <Form.Item
          label="Headcount"
          name="headcount"
          rules={[
            {
              required: true,
              message: "Please enter the headcount",
            },
            {
              type: "number",
              min: 1,
            },
            {
              type: "number",
              max: 9999,
            },
          ]}
        >
          <CustomInputNumber
            controls={false}
            style={{ width: "100%" }}
            precision={0}
            placeholder="Number of people involved in the work"
          />
        </Form.Item>
        {paymentMethod === PaymentMethodValueType.Negotiable ? (
          <Form.Item
            label="Total Cost"
            name="totalCost"
            rules={[
              { required: true },
              {
                type: "number",
                min: 0,
              },
              {
                type: "number",
                max: 999999999,
              },
            ]}
          >
            <CustomInputNumber
              addonBefore={<SelectCurrency field="totalCost" />}
              controls={false}
              precision={2}
            />
          </Form.Item>
        ) : (
          <>
            <Form.Item
              label="Unit Price"
              name="unit_price"
              rules={[
                { required: true },
                {
                  type: "number",
                  min: 0,
                },
                {
                  type: "number",
                  max: 999999999,
                },
              ]}
            >
              <CustomInputNumber
                addonBefore={<SelectCurrency field="unit_price" />}
                controls={false}
                precision={2}
                placeholder={`Unit price ${
                  calculateRateUnit ? `per ${calculateRateUnit}` : ""
                }`}
              />
            </Form.Item>
            {paymentMethod === PaymentMethodValueType.Paperwork ? (
              <Form.Item
                label="Quantity (Total)"
                name="quantity"
                rules={[
                  { required: true },
                  {
                    type: "number",
                    min: 0.01,
                  },
                  {
                    type: "number",
                    max: 999999,
                  },
                  {
                    validator: () => {
                      if (!calculateRateUnit)
                        return Promise.reject(
                          new Error("Please select an unit"),
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <CustomInputNumber
                  addonAfter={
                    <SelectUnit
                      field="quantity"
                      options={unitforPaperworkOptions}
                    />
                  }
                  controls={false}
                  precision={2}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Duration (Per Person)"
                name="duration"
                rules={[
                  { required: true },
                  {
                    type: "number",
                    min: 0.01,
                  },
                  {
                    type: "number",
                    max: 999999,
                  },
                  {
                    validator: (_, val) => {
                      if (!calculateRateUnit)
                        return Promise.reject(
                          new Error("Please select an unit"),
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <CustomInputNumber
                  addonAfter={
                    <SelectUnit field="duration" options={unitforTimeOptions} />
                  }
                  controls={false}
                  precision={2}
                />
              </Form.Item>
            )}
          </>
        )}
        <Form.Item hidden name="unit" />
        <Form.Item hidden name="currency" />
        <Form.Item hidden name="totalAmountEx" />
      </Form>
      <Divider />
      <div className="flex">
        <div className="w-[450px]">
          <span className="bg-white rounded-full inline-block w-6 h-6 mr-2">
            <ExclamationCircleFilled className="text-[#FF9900] text-2xl align-[-6px]" />
          </span>
          <div className="text-center text-[#999]">
            <Image className="mt-4 mb-5" src={paySecurityImg} preview={false} />
            <div className="mb-4">
              Stats show that 44% of freelancers have <br /> experienced not
              getting paid EVER by clients.
            </div>
            <div className="mb-4">
              74% of freelancers report not getting paid on time.
            </div>
            <div>Let LeyLine protect you from such unhappy experiences.</div>
          </div>
        </div>
        <div className="flex-1">
          <div className="font-medium text-xl">Total Fee</div>
          <FieldRow
            label="Total Cost"
            labelDesc={
              paymentMethod === PaymentMethodValueType.Negotiable
                ? ""
                : `Total Cost = ${
                    paymentMethod === PaymentMethodValueType.Time
                      ? "Headcount * Duration"
                      : "Quantity"
                  } * Unit Price`
            }
            value={`${currency} ${
              paymentMethod === PaymentMethodValueType.Negotiable
                ? totalCost?.toFixed(2) || "0.00"
                : totalAmountEx
            }`}
          />
          <FieldRow
            label="Service Fee"
            labelDesc={`${(serviceFeeRate || 0.1) * 100}% Service Fee`}
            value={`${currency} -${serviceFee}`}
            valueClassName="text-[#999]"
          />
          <FieldRow
            hideBorder
            label="You’ll Receive "
            value={`${currency} ${realIncome}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Step2;
