const nonEntertainmentIndustryList = [
  // "Entertainment",
  "Media & Communications",
  "Construction",
  "Design (Non-Entertainment)",
  "Arts",
  "Education",
  "Recreation & Travel",
  "Corporate Services",
  "Consumer Goods",
  "Energy & Mining",
  "Manufacturing",
  "Finance",
  "Health Care",
  "Transportation & Logistics",
  "Software & IT Services",
  "Real Estate",
  "Legal",
  "Hardware & Networking",
  "Agriculture",
  "Nonprofit",
  "Wellness & Fitness",
  "Public Safety",
  "Public Administration",
];

const entertainmentIndustryList = [
  "Entertainment (General)",
  "Animation",
  "Games",
  "Advisement",
  "Media Production",
  "Motion Pictures & Film",
  "Music",
  "Installation Art",
  "Design",
  "Virtual Human",
  "Visual Arts",
  "Over-the-Top Live Broadcast",
];

export const industryOptions = [
  {
    label: "Entertainment",
    key: "Entertainment",
    options: entertainmentIndustryList.map((industry) => ({
      value: industry,
      label: industry,
      key: `Entertainment-${industry}`,
    })),
  },
  {
    label: "Non-Entertainment",
    key: "Non-Entertainment",
    options: nonEntertainmentIndustryList.map((industry) => ({
      value: industry,
      label: industry,
      key: `Non-Entertainment-${industry}`,
    })),
  },
];

export const companySizeOptions = [
  {
    key: "number-of-employees",
    label: "Number of Employees",
    options: [
      { key: "0-19", value: "0-19", label: "0-19" },
      { key: "20-49", value: "20-49", label: "20-49" },
      { key: "50-199", value: "50-199", label: "50-199" },
      { key: "200-499", value: "200-499", label: "200-499" },
      { key: "500-999", value: "500-999", label: "500-999" },
      { key: "1000-9999", value: "1000-9999", label: "1000-9999" },
      {
        key: "more-than-10000",
        value: "More than 10000",
        label: "More than 10000",
      },
    ],
  },
];

export const softwareOptions = [
  { value: "3DBee", label: "3DBee" },
  {
    value: "Mol3D",
    label: "Mol3D",
  },
  {
    value: "The Grove 3D",
    label: "The Grove 3D",
  },
  {
    value: "3DF Zephyr",
    label: "3DF Zephyr",
  },
  {
    value: "Visual Designer 3D",
    label: "Visual Designer 3D",
  },
  {
    value: "123D",
    label: "123D",
  },
  {
    value: "LightWave 3D",
    label: "LightWave 3D",
  },
  {
    value: "Unfold 3D",
    label: "Unfold 3D",
  },
  {
    value: "3D-Coat",
    label: "3D-Coat",
  },
  {
    value: "3ds Max",
    label: "3ds Max",
  },
  {
    value: "After Effects",
    label: "After Effects",
  },
  {
    value: "Arnold",
    label: "Arnold",
  },
  {
    value: "Blender",
    label: "Blender",
  },
  {
    value: "Cinema 4D",
    label: "Cinema 4D",
  },
  {
    value: "Clip Studio Paint",
    label: "Clip Studio Paint",
  },
  {
    value: "DAZ Studio",
    label: "DAZ Studio",
  },
  {
    value: "GIMP",
    label: "GIMP",
  },
  {
    value: "Illustrator",
    label: "Illustrator",
  },
  {
    value: "Keyshot",
    label: "Keyshot",
  },
  {
    value: "Krita",
    label: "Krita",
  },
  {
    value: "Manga Studio",
    label: "Manga Studio",
  },
  {
    value: "Mari",
    label: "Mari",
  },
  {
    value: "Marmoset",
    label: "Marmoset",
  },
  {
    value: "Marvelous Designer",
    label: "Marvelous Designer",
  },
  {
    value: "Maya",
    label: "Maya",
  },
  {
    value: "Mental Ray",
    label: "Mental Ray",
  },
  {
    value: "Mol",
    label: "Mol",
  },
  {
    value: "Mudbox",
    label: "Mudbox",
  },
  {
    value: "Nuke",
    label: "Nuke",
  },
  {
    value: "Painter",
    label: "Painter",
  },
  {
    value: "PaintTool Sal",
    label: "PaintTool Sal",
  },
  {
    value: "Photoshop",
    label: "Photoshop",
  },
  {
    value: "Procreate",
    label: "Procreate",
  },
  {
    value: "Quixel Suite",
    label: "Quixel Suite",
  },
  {
    value: "Sketchbook Pro",
    label: "Sketchbook Pro",
  },
  {
    value: "Substance Designer",
    label: "Substance Designer",
  },
  {
    value: "Substance Painter",
    label: "Substance Painter",
  },
  {
    value: "Unity",
    label: "Unity",
  },
  {
    value: "Unreal Engine",
    label: "Unreal Engine",
  },
  {
    value: "V-Ray",
    label: "V-Ray",
  },
  {
    value: "xNormal",
    label: "xNormal",
  },
  {
    value: "Zbrush",
    label: "Zbrush",
  },
  {
    value: "Houdini",
    label: "Houdini",
  },
  {
    value: "Redshift",
    label: "Redshift",
  },
  {
    value: "Xgen",
    label: "Xgen",
  },
  {
    value: "Yeti",
    label: "Yeti",
  },
  {
    value: "Python",
    label: "Python",
  },
  {
    value: "Makehuman",
    label: "Makehuman",
  },
  {
    value: "Alias Design",
    label: "Alias Design",
  },
  {
    value: "Blackmagic Design Fusion",
    label: "Blackmagic Design Fusion",
  },
  {
    value: "InDesign",
    label: "InDesign",
  },
  {
    value: "Affinity Designer",
    label: "Affinity Designer",
  },
  {
    value: "Maverick",
    label: "Maverick",
  },
  {
    value: "Amadine",
    label: "Amadine",
  },
  {
    value: "MasterpieceVR",
    label: "MasterpieceVR",
  },
  {
    value: "Braid Maker",
    label: "Braid Maker",
  },
  {
    value: "RenderMan",
    label: "RenderMan",
  },
  {
    value: "Artomatix",
    label: "Artomatix",
  },
  {
    value: "EmberGen",
    label: "EmberGen",
  },
  {
    value: "Amberlight",
    label: "Amberlight",
  },
  {
    value: "Combustion",
    label: "Combustion",
  },
  {
    value: "Shotgun",
    label: "Shotgun",
  },
  {
    value: "PTGui",
    label: "PTGui",
  },
  {
    value: "Quill",
    label: "Quill",
  },
  {
    value: "Tayasui Sketches",
    label: "Tayasui Sketches",
  },
  {
    value: "Tayasui Sketches Mobile App",
    label: "Tayasui Sketches Mobile App",
  },
  {
    value: "Tayasui Sketches Pro",
    label: "Tayasui Sketches Pro",
  },
  {
    value: "MotionBuilder",
    label: "MotionBuilder",
  },
  {
    value: "CoreIDRAW Graphics Suite",
    label: "CoreIDRAW Graphics Suite",
  },
  {
    value: "SketchUp",
    label: "SketchUp",
  },
  {
    value: "PyxelEdit",
    label: "PyxelEdit",
  },
  {
    value: "Hard Ops",
    label: "Hard Ops",
  },
  {
    value: "HardMesh",
    label: "HardMesh",
  },
  {
    value: "Realtime Board",
    label: "Realtime Board",
  },
  {
    value: "Storyboard Pro",
    label: "Storyboard Pro",
  },
  {
    value: "World",
    label: "World",
  },
  {
    value: "Dreams",
    label: "Dreams",
  },
  {
    value: "Avid Media Composer",
    label: "Avid Media Composer",
  },
  {
    value: "CrazyBump",
    label: "CrazyBump",
  },
  {
    value: "Poser",
    label: "Poser",
  },
  {
    value: "Final Draft",
    label: "Final Draft",
  },
  {
    value: "PlantCatalog",
    label: "PlantCatalog",
  },
  {
    value: "RealityCapture",
    label: "RealityCapture",
  },
  {
    value: "finalRender",
    label: "finalRender",
  },
  {
    value: "Balsamiq Mockup",
    label: "Balsamiq Mockup",
  },
  {
    value: "Adobe XD",
    label: "Adobe XD",
  },
  {
    value: "Adobe Lightroom",
    label: "Adobe Lightroom",
  },
  {
    value: "Pencil2D",
    label: "Pencil2D",
  },
  {
    value: "Live2D",
    label: "Live2D",
  },
  {
    value: "Spine 2D",
    label: "Spine 2D",
  },
  {
    value: "Fstorm",
    label: "Fstorm",
  },
  {
    value: "Substance Source",
    label: "Substance Source",
  },
  {
    value: "Sublime Text",
    label: "Sublime Text",
  },
  {
    value: "Maxwell Render",
    label: "Maxwell Render",
  },
  {
    value: "Octane Render",
    label: "Octane Render",
  },
  {
    value: "Hydra Render",
    label: "Hydra Render",
  },
  {
    value: "Corona Render",
    label: "Corona Render",
  },
  {
    value: "GuerillaRender",
    label: "GuerillaRender",
  },
  {
    value: "LuxRender",
    label: "LuxRender",
  },
  {
    value: "Thea Render",
    label: "Thea Render",
  },
  {
    value: "Twinmotion",
    label: "Twinmotion",
  },
  {
    value: "Modo",
    label: "Modo",
  },
  {
    value: "Mocha",
    label: "Mocha",
  },
  {
    value: "Harmony",
    label: "Harmony",
  },
  {
    value: "Mixamo",
    label: "Mixamo",
  },
  {
    value: "Marmoset Toolbag",
    label: "Marmoset Toolbag",
  },
  {
    value: "Presagis Creator",
    label: "Presagis Creator",
  },
];

export const skillOptions = [
  "Video Production",
  "2D Animation",
  "3D Animation",
  "Creature Design",
  "3D Modeling",
  "3D Printing",
  "3D Scanning",
  "3D Visualization",
  "AI Video",
  "Anatomy",
  "App Development",
  "Architectural Design",
  "Architectural Visualization",
  "VFX Supervision",
  "Art Direction",
  "Book Cover Illustration",
  "Cartooning",
  "Character Animation",
  "Character Design",
  "Character Modeling",
  "Cinematics",
  "Cloth Simulation",
  "Color Grading",
  "Color Management",
  "Compositing",
  "Concept Art",
  "Concept Design",
  "Costume Design",
  "Creature Modeling",
  "Digital Painting",
  "Digital Sculpting",
  "Drawing",
  "Environment Design",
  "Facial Rigging & Animation",
  "Fashion Design",
  "Film Editing",
  "Fluid Simulation",
  "Game Design",
  "Game Development",
  "Graphics Design",
  "HDRI Photography",
  "Hair/Fur Simulation",
  "Hard Surface Modeling",
  "High-poly Modeling",
  "Illustration",
  "Kitbashing",
  "Lighting",
  "Look Development",
  "Low-poly Modeling",
  "Map Design",
  "Marketing Illustration",
  "Matte Painting",
  "Medical Illustration",
  "Mel Scripting",
  "Motion Capture",
  "Motion Graphics",
  "Painting",
  "Particle Effects",
  "Photo-manipulation",
  "Photogrammetry",
  "Physically Based Rendering",
  "Portraiture",
  "Post-production",
  "Pre-viz",
  "Product Design",
  "Production Design",
  "Programming",
  "Project Management",
  "Prop Design",
  "Prop Modeling",
  "Python Scripting",
  "Rendering",
  "Retopology",
  "Rigging",
  "Rotoscoping",
  "Scientific Animation",
  "Screenwriting",
  "Sculpting",
  "Server Administration",
  "Shaders",
  "Shading",
  "Sketching",
  "Software Development",
  "Sound Design",
  "Storyboarding",
  "Storytelling",
  "Teaching",
  "Team Management",
  "Texturing",
  "Traditional Art",
  "Transport Design",
  "Typography",
  "UI Design",
  "UV Mapping",
  "User Interface Design",
  "Vector Illustration",
  "Vehicle Modeling",
  "Video Editing",
  "Visual Development",
  "Visual Effects",
  "Voice Acting",
  "Watercolors",
  "Weapon Design",
  "Weapon Modeling",
  "Web Design",
  "Web Development",
  "World Building",
].map((item) => ({ value: item, label: item }));

export const unitforPaperworkOptions = [
  "film",
  "episode",
  "scene",
  "shot",
  "set",
  "page",
  "piece",
  "frame",
  "second",
  "minute",
].map((item) => ({ value: item, label: item }));

export const unitforTimeOptions = ["hour", "day", "week", "month"].map(
  (item) => ({ value: item, label: item }),
);

export const projectDepartmentOptions = [
  "Script",
  "Storyboarding",
  "Editing",
  "Concept Design",
  "Modelling",
  "Location Scouting",
  "Surfacing",
  "Rigging",
  "Grooming",
  "Layout",
  "Animation",
  "Effect",
  "Lighting",
  "Compositing",
  "Mattepainting",
  "TD",
  "Production",
];
