import {
  CustomInputNumber,
  CustomRadio,
  CustomSelect,
} from "@/components/customComponents";
import { paymentMethodOptions } from "@/pages/jobs/components/signContractModal/utils";
import {
  unitforPaperworkOptions,
  unitforTimeOptions,
} from "@/utils/selectionList";
import {
  Col,
  Form,
  FormInstance,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
  theme,
} from "antd";
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

const { useToken } = theme;

interface Props {
  form: FormInstance;
  currency: string;
  hideCNY?: boolean;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  normalLayout?: boolean;
  sourceFrom?: string;
}

export enum JobPaymentMethod {
  Paperwork = "Piece-rate",
  Time = "Time-based",
  Negotiable = "Negotiable",
  Undefined = "null",
}

export enum PaymentMethodValueType {
  Paperwork = "Piece-rate",
  Time = "Time-based",
  Negotiable = "Negotiable",
}

export enum PaymentMethodNameType {
  Paperwork = "By Unit",
  Time = "Wage Rate",
  Negotiable = "Entire Project",
}

export const paymentMethodMap = {
  [PaymentMethodValueType.Paperwork]: PaymentMethodNameType.Paperwork,
  [PaymentMethodValueType.Time]: PaymentMethodNameType.Time,
  [PaymentMethodValueType.Negotiable]: PaymentMethodNameType.Negotiable,
};

export const PaymentMethodComp = (
  {
    form,
    currency,
    setCurrency,
    hideCNY = false,
    normalLayout = false,
    sourceFrom,
  }: Props,
  ref: Ref<unknown> | undefined,
) => {
  const [calculateRateUnit, setCalculateRateUnit] = useState("");
  const paymentMethod = Form.useWatch("payment_method", form);
  const totalAmount = Form.useWatch("totalAmount", form);
  const totalAmountEx = Form.useWatch("totalAmountEx", form);
  const { token } = useToken();

  useEffect(() => {
    if (paymentMethod === PaymentMethodValueType.Paperwork) {
      form.setFieldValue(["budget", "unit"], "piece");
      setCalculateRateUnit("piece");
    } else if (paymentMethod === PaymentMethodValueType.Time) {
      form.setFieldValue(["budget", "unit"], "hour");
      setCalculateRateUnit("hour");
    } else setCalculateRateUnit("");
  }, [form, paymentMethod]);

  const currencyOptions = useMemo(() => {
    const options = [
      { label: "USD", value: "USD" },
      { label: "CNY", value: "CNY" },
    ];
    if (hideCNY) options.splice(1, 1);
    return options;
  }, [hideCNY]);

  useImperativeHandle(ref, () => ({
    transformFormValues: () => {
      const { headcount, budget } = form.getFieldsValue();
      const { quantity, duration_per_ppl, unit, unit_price } = budget || {};

      return {
        headcount,
        payment_method: paymentMethod,
        count:
          paymentMethod !== JobPaymentMethod.Negotiable &&
          paymentMethod !== JobPaymentMethod.Undefined
            ? paymentMethod === JobPaymentMethod.Paperwork
              ? quantity
              : parseFloat(duration_per_ppl)
            : null,
        count_unit:
          paymentMethod !== JobPaymentMethod.Negotiable &&
          paymentMethod !== JobPaymentMethod.Undefined
            ? unit
            : null,
        rate:
          paymentMethod !== JobPaymentMethod.Negotiable &&
          paymentMethod !== JobPaymentMethod.Undefined
            ? unit_price
            : null,
        rate_unit:
          paymentMethod === JobPaymentMethod.Time
            ? `/person/${calculateRateUnit}`
            : "",
        total_cost:
          paymentMethod === JobPaymentMethod.Negotiable
            ? parseFloat(totalAmount)
            : parseFloat(totalAmountEx),
      };
    },
  }));

  const selectCurrency = (
    <CustomSelect
      value={currency}
      onChange={setCurrency}
      options={currencyOptions}
      style={{ width: 140 }}
    />
  );
  const selectUnit = (
    <CustomSelect
      style={{ width: 140 }}
      placeholder="Select unit"
      options={
        paymentMethod === JobPaymentMethod.Paperwork
          ? unitforPaperworkOptions
          : unitforTimeOptions
      }
      value={calculateRateUnit}
      onChange={(v) => {
        form.setFieldValue(["budget", "unit"], v);
        setCalculateRateUnit(v);
        // @ts-ignore
        recalculatePrice(v);
      }}
    />
  );

  // @ts-ignore
  const recalculatePrice = () => {
    const headcount = form?.getFieldValue("headcount");
    const budget = form?.getFieldValue("budget") || {};
    const { quantity, duration_per_ppl, unit, unit_price } = budget;

    if (!paymentMethod) return;
    if (!unit_price) form?.setFieldValue("totalAmountEx", "0.00");

    if (paymentMethod === JobPaymentMethod.Paperwork) {
      if (quantity) {
        const price = parseInt(quantity) * parseFloat(unit_price);
        form?.setFieldValue("totalAmountEx", price.toFixed(2));
      } else form?.setFieldValue("totalAmountEx", "0.00");
    } else if (paymentMethod === JobPaymentMethod.Time) {
      if (headcount && duration_per_ppl) {
        const price =
          parseInt(headcount) *
          parseFloat(duration_per_ppl) *
          parseFloat(unit_price ?? 0);
        form?.setFieldValue("totalAmountEx", price.toFixed(2));
      } else form?.setFieldValue("totalAmountEx", "0.00");
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // @ts-ignore
    recalculatePrice();
  }, [paymentMethod]);

  return (
    <>
      <Form.Item
        label="Payment Method"
        name="payment_method"
        rules={[{ required: true, message: "Please select a payment method" }]}
      >
        <CustomRadio.Group options={paymentMethodOptions} />
      </Form.Item>
      {sourceFrom === "jobCreate" && (
        <Form.Item
          label="Preferred quotation method?"
          name="is_preferred_quotation_method"
        >
          <Switch />
        </Form.Item>
      )}
      <Form.Item
        label="Headcount"
        name="headcount"
        rules={[
          {
            required: true,
            message: "Please enter the headcount",
          },
          {
            type: "number",
            min: 1,
          },
          {
            type: "number",
            max: 9999,
          },
        ]}
      >
        <InputNumber
          controls={false}
          style={{ width: "100%" }}
          precision={0}
          placeholder="Number of people involved in the work"
          onChange={(v) => {
            // @ts-ignore
            recalculatePrice(v);
          }}
        />
      </Form.Item>

      {[JobPaymentMethod.Time, JobPaymentMethod.Paperwork].includes(
        paymentMethod,
      ) ? (
        <>
          <Form.Item
            label="Unit Price"
            name={["budget", "unit_price"]}
            rules={[
              { required: true },
              {
                type: "number",
                min: 0,
              },
              {
                type: "number",
                max: 999999999,
              },
            ]}
          >
            <CustomInputNumber
              addonBefore={selectCurrency}
              controls={false}
              precision={2}
              placeholder={`Unit price ${
                calculateRateUnit ? `per ${calculateRateUnit}` : ""
              }`}
              onChange={(v) => {
                // @ts-ignore
                recalculatePrice(v);
              }}
            />
          </Form.Item>
          {paymentMethod === JobPaymentMethod.Paperwork ? (
            <Form.Item
              label="Quantity (Total)"
              name={["budget", "quantity"]}
              rules={[
                { required: true },
                {
                  type: "number",
                  min: 0.01,
                },
                {
                  type: "number",
                  max: 999999,
                },
              ]}
            >
              <CustomInputNumber
                addonAfter={selectUnit}
                controls={false}
                precision={2}
                onChange={(v) => {
                  // @ts-ignore
                  recalculatePrice(v);
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="Duration (Per Person)"
              name={["budget", "duration_per_ppl"]}
              rules={[
                { required: true },
                {
                  type: "number",
                  min: 0.01,
                },
                {
                  type: "number",
                  max: 999999,
                },
              ]}
            >
              <CustomInputNumber
                addonAfter={selectUnit}
                controls={false}
                precision={2}
                onChange={(v) => {
                  // @ts-ignore
                  recalculatePrice(v);
                }}
              />
            </Form.Item>
          )}
          {/* <Form.Item
            label="Unit"
            name={["budget", "unit"]}
            rules={[{ required: true }]}
          >
            
          </Form.Item> */}
        </>
      ) : (
        <></>
      )}

      {paymentMethod === JobPaymentMethod.Negotiable ? (
        <Form.Item
          label="Total Cost"
          name="totalAmount"
          rules={[
            {
              required: true,
              message: "Please enter the total amount",
            },
            {
              type: "number",
              min: 0,
            },
            {
              type: "number",
              max: 999999999,
            },
          ]}
        >
          <CustomInputNumber
            controls={false}
            precision={2}
            addonBefore={selectCurrency}
          />
        </Form.Item>
      ) : (
        <Form.Item
          label="Total Cost"
          name="totalAmountEx"
          rules={[
            {
              required: false,
              message: "Please enter the total amount",
            },
          ]}
        >
          <div className="mt-1">
            <div className="w-[180]" style={{ color: token.colorPrimary }}>
              {currency} {totalAmountEx || 0}
            </div>
            <div className="text-white/[.5]">
              Total Cost ={" "}
              {paymentMethod === JobPaymentMethod.Paperwork
                ? "Quantity * Unit Price"
                : "Headcount * Duration * Unit Price"}
            </div>
          </div>
        </Form.Item>
      )}
      <Form.Item hidden name={["budget", "unit"]} />
    </>
  );
};

export default forwardRef(PaymentMethodComp);
