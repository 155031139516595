import { Checkbox, CheckboxProps, ConfigProvider } from "antd";
import styles from "./styles//checkbox.module.css";

export const CustomCheckbox = ({ ...props }: CheckboxProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "rgba(255, 255, 255, 0.50)",
          colorBorder: "transparent",
          colorPrimaryBorder: "transparent",
          colorPrimary: "#00ADAE",
          controlInteractiveSize: 20,
          colorText: "#fff",
          colorTextDisabled: "#999",
          borderRadius: 4,
        },
      }}
    >
      <Checkbox rootClassName={styles.checkboxWrap} {...props} />
    </ConfigProvider>
  );
};
