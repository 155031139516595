import {
  DownloadOutlined,
  EnvironmentFilled,
  SafetyCertificateFilled,
} from "@ant-design/icons";
import { IResourceComponentsProps, useParsed } from "@refinedev/core";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { axiosInstance } from "../../utils/http";

import { showAbsoluteUrl } from "@/utils/common";
import { useApiUrl, useGo, useTranslate } from "@refinedev/core";
import { HttpError, useCustom } from "@refinedev/core";
import { formatToMiddleStyle } from "../../utils/date";

import moment from "moment";

import {
  Avatar,
  Button,
  Card,
  Col,
  ConfigProvider,
  Divider,
  Grid,
  Image,
  Result,
  Row,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";

const { Title, Text, Link } = Typography;
const { Meta } = Card;
import { BsBuildings } from "react-icons/bs";
import { Link as LinkDom, useNavigate } from "react-router-dom";
import { useConfigProvider } from "../../contexts";

import { Moment } from "moment";
import { JobUnmatchModal } from "../jobs/modalUnmatch";
import { JobSetCloseModal } from "./modalCloseJob";
import { JobSetOpenModal } from "./modalOpenJob";

import { CustomEmpty } from "@/components/customComponents";
import themeConfig from "@/constants/themeConfig";
import JobGroup from "@/pages/Applications/components/JobGroup";
import { JobItem, ReceGroupEnum } from "@/pages/Applications/utils";
import ProjectStatusTag from "@/pages/enterprises/components/ProjectStatusTag";
import ShowFileOrLink from "@/pages/enterprises/components/ShowFileOrLink";
import CompanyInfo from "@/pages/jobs/components/CompanyInfo";
import JobProperties from "@/pages/jobs/components/JobProperties";
import { GetJobDetailResponse } from "@/pages/jobs/type";
import { getReceivedApplications } from "@/pages/users/services";
import { useGlobalStore } from "@/stores";
import { judgeIsCompanyRole } from "@/utils/imUtils";
import { useRequest } from "ahooks";
import { ArtistApplicantCard } from "../../components/users/ArtistApplicantCard";

export const JobDetail: React.FC<IResourceComponentsProps> = () => {
  const { mode, setMode } = useConfigProvider();
  const breakpoint = Grid.useBreakpoint();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("info");
  const [isUnmatchModalOpen, setIsUnmatchModalOpen] = useState(false);
  const [isSetCloseModalOpen, setIsSetCloseModalOpen] = useState(false);
  const [isSetOpenModalOpen, setIsSetOpenModalOpen] = useState(false);
  const [unmatchReason, setUnmatchReason] = useState([]);
  const { currentRole } = useGlobalStore((state) => state);
  const isCompanyRole = judgeIsCompanyRole(currentRole);

  const { resource, action, id, pathname } = useParsed();

  useEffect(() => {
    console.log(resource, action, id, pathname);
  }, [resource, action, id, pathname]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setActiveTab("info");
  }, [isCompanyRole]);

  // hack.
  // TODO: get department id directly from the corresponding APIs
  // const [departmentId, setDepartmentId] = useState("");

  const apiUrl = useApiUrl();
  const go = useGo();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const [applicationsData, setApplicationsData] = useState<JobItem[]>([]);
  const applicationsDataRef = useRef<JobItem[]>([]);

  const { data, isLoading, isError, refetch } = useCustom<GetJobDetailResponse>(
    {
      url: `${apiUrl}/jobs/${id}`,
      method: "get",
      config: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        query: {},
      },
      queryOptions: {
        staleTime: 1000,
      },
    },
  );

  const {
    loading,
    run: fetchItems,
    params,
  } = useRequest(
    (item?: {
      job_id?: string;
      published_list_status?: ReceGroupEnum;
      sub_status?: number;
      project_id?: string;
      sort?: string;
      only_open?: boolean;
    }) => {
      const { published_list_status, sub_status, sort } = item || {};
      return getReceivedApplications({
        job_id: id as string,
        published_list_status,
        sub_status,
        sort,
        only_open: false,
      });
    },
    {
      manual: true,
      onSuccess(res) {
        const { job_id, published_list_status } = params[0] || {};
        if (job_id && published_list_status) {
          const replaceIndex = applicationsDataRef.current.findIndex(
            (item) => item.job_id === job_id,
          );
          applicationsDataRef.current.splice(
            replaceIndex,
            1,
            res.data.job_application_items.length > 0
              ? res.data.job_application_items[0]
              : { job_id, applications: [] },
          );
          setApplicationsData([...applicationsDataRef.current]);
        } else {
          setApplicationsData(res.data.job_application_items);
        }
      },
      onError(e) {
        message.error(
          // @ts-ignore
          e?.errors?.detail ||
            "An unknown error occurred. Please try again later.",
        );
      },
    },
  );

  useEffect(() => {
    if (!isLoading && data?.data?.id) {
      const { project_id, company_id, user_id } = data.data;
      sessionStorage.setItem("jobtouserid", user_id);
      sessionStorage.setItem("jobtoprojectid", project_id);
      sessionStorage.setItem("jobtocompanyid", company_id);
    }
  }, [isLoading, data]);

  useEffect(() => {
    activeTab === "received" && fetchItems();
  }, [fetchItems, activeTab]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  const job: GetJobDetailResponse = data?.data ?? {};

  const operations = (
    <Space>
      {job.is_enabled ? (
        <Button
          danger
          type="primary"
          onClick={() => {
            setIsSetCloseModalOpen(true);
          }}
        >
          Close
        </Button>
      ) : (
        <Button
          danger
          type="primary"
          onClick={() => {
            setIsSetOpenModalOpen(true);
          }}
        >
          Open
        </Button>
      )}
      <Button
        type="primary"
        onClick={() => {
          go({ to: `/jobs/edit/${id}` });
        }}
      >
        Edit
      </Button>
      <Button onClick={() => navigate(-1)}>Back</Button>
    </Space>
  );
  const handledSoftware = job.software?.filter((v) => v.trim().length > 0);

  const askUnmatch = () => {
    setIsUnmatchModalOpen(true);
  };

  const renderInfoTab = () => {
    if (activeTab !== "info") {
      return <React.Fragment />;
    }
    return (
      <>
        {breakpoint.xl ? (
          <Row>
            <Col span={17}>
              <Space direction="vertical" className="w-full">
                <Title level={3}>{job.title}</Title>
                {/* <Title level={5}>Group Description</Title>
                <Text>{job.department_description}</Text> */}

                <Title level={5} className="mt-4">
                  Skills and Requirement
                </Title>
                <Text className="whitespace-pre-wrap">{job.description}</Text>
                <JobProperties job={job} />
                <Title level={5} className="mt-4">
                  Software
                </Title>
                <p>
                  {handledSoftware?.length > 0
                    ? handledSoftware?.map((v) => {
                        return (
                          <Tag color="#00ced1" className="mr-1 mb-1">
                            {v}
                          </Tag>
                        );
                      })
                    : "--"}
                </p>

                <Title level={5} className="mt-4">
                  Benchmark
                </Title>
                <ShowFileOrLink {...job.benchmark} />

                <Title level={5} className="mt-4">
                  Test
                </Title>
                <ShowFileOrLink {...job.test} />
              </Space>
            </Col>
            <Col span={7}>
              {job.is_enabled ? (
                <Tag color="#87d068">Open</Tag>
              ) : (
                <Tag color="#666">Closed</Tag>
              )}
              <Space className="ml-2 mb-3">
                <div className="text-center">
                  <div className="text-sm text-[#999]">Viewed</div>
                  <div>{job.total_views}</div>
                </div>
                <div className="text-center">
                  <div className="text-sm text-[#999]">Communicated</div>
                  <div>{job.total_communicated || 0}</div>
                </div>
                <div className="text-center">
                  <div className="text-sm text-[#999]">Applied</div>
                  <div>{job.total_applicants}</div>
                </div>
              </Space>
              <Card
                hoverable
                className=""
                bodyStyle={{ width: "100%" }}
                cover={
                  <img
                    alt="job cover"
                    // style={{ width: "100%" }}
                    style={{
                      minHeight: "10rem",
                      maxHeight: "10rem",
                      objectFit: "cover",
                    }}
                    className="w-full"
                    src={IMG_PREFIX + job.project_thumbnail_url}
                  />
                }
              >
                <Row gutter={20}>
                  <Col span={20}>
                    <Title level={3}>{job.project_name}</Title>
                    <p className="mt-4">
                      <Text type="secondary">
                        {formatToMiddleStyle(job.project_start_date)}
                        &nbsp;to&nbsp;
                        {formatToMiddleStyle(job.project_end_date)}
                      </Text>
                    </p>
                    <p className="mt-4">
                      <ProjectStatusTag status={job.progress_status} />
                      {job.is_project_verified ? (
                        <Tag color="#87d068">Verified</Tag>
                      ) : (
                        <></>
                      )}
                    </p>
                    <Text type="secondary">{job.project_description}</Text>
                  </Col>

                  <Divider type="horizontal" />
                  <CompanyInfo job={job} />
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <>
            <Space direction="vertical" className="w-full">
              <Title level={3}>{job.title}</Title>

              {/* <Title level={5}>Group Description</Title>
              <Text>{job.department_description}</Text> */}

              <Title level={5} className="mt-4">
                Skills and Requirement
              </Title>
              <Text className="whitespace-pre-wrap">{job.description}</Text>
              <JobProperties job={job} />
              <Title level={5} className="mt-4">
                Software
              </Title>
              <p>
                {job.software
                  ?.filter((v) => v.trim().length > 0)
                  ?.map((v) => {
                    return (
                      <Tag color="#00ced1" className="mr-1 mb-1">
                        {v}
                      </Tag>
                    );
                  })}
              </p>

              <Title level={5} className="mt-4">
                Benchmark
              </Title>
              <ShowFileOrLink {...job.benchmark} />
              <Title level={5} className="mt-4">
                Test
              </Title>
              <ShowFileOrLink {...job.test} />
            </Space>

            {job.is_enabled ? (
              <Tag color="#87d068">Open</Tag>
            ) : (
              <Tag color="#666">Closed</Tag>
            )}
            <Space className="ml-2 mt-8">
              <div className="text-center">
                <div className="text-sm text-[#999]">Viewed</div>
                <div>{job.total_views}</div>
              </div>
              <div className="text-center">
                <div className="text-sm text-[#999]">Communicated</div>
                <div>{job.total_communicated || 0}</div>
              </div>
              <div className="text-center">
                <div className="text-sm text-[#999]">Applied</div>
                <div>{job.total_applicants}</div>
              </div>
            </Space>

            <Card
              hoverable
              className="mt-8"
              bodyStyle={{ width: "100%" }}
              cover={
                <img
                  alt="job cover"
                  // style={{ width: "100%" }}
                  className="w-full"
                  src={IMG_PREFIX + job.project_thumbnail_url}
                />
              }
            >
              <Row gutter={20}>
                <Col span={20}>
                  <Title level={3}>{job.project_name}</Title>
                  <p className="mt-4">
                    <Text type="secondary">
                      {formatToMiddleStyle(job.project_start_date)}
                      &nbsp;to&nbsp;
                      {formatToMiddleStyle(job.project_end_date)}
                    </Text>
                  </p>
                  <p className="mt-4">
                    <ProjectStatusTag status={job.progress_status} />
                    {job.is_project_verified ? (
                      <Tag color="#87d068">Verified</Tag>
                    ) : (
                      <></>
                    )}
                  </p>
                  <Text type="secondary">{job.project_description}</Text>
                </Col>
                <Divider type="horizontal" />
                <CompanyInfo job={job} />
              </Row>
            </Card>
          </>
        )}
      </>
    );
  };
  const renderReceivedTab = () => {
    if (activeTab !== "received") {
      return <React.Fragment />;
    }
    if (applicationsData?.length > 0) {
      return (
        <ConfigProvider theme={themeConfig}>
          <Spin spinning={loading}>
            <JobGroup
              item={applicationsData[0]}
              fnRefresh={fetchItems}
              hideJobTitle
            />
          </Spin>
        </ConfigProvider>
      );
    }
    return <CustomEmpty className="mx-auto mt-[140px]" />;
  };

  return (
    <>
      <JobUnmatchModal // @ts-ignore
        open={isUnmatchModalOpen} // @ts-ignore
        onOk={(reasons) => {
          setUnmatchReason(reasons);
          setIsUnmatchModalOpen(false);
        }}
        onCancel={() => {
          setIsUnmatchModalOpen(false);
        }}
      />

      <JobSetCloseModal // @ts-ignore
        open={isSetCloseModalOpen} // @ts-ignore
        onOk={async () => {
          const res = await axiosInstance.put(
            `/jobs/${job.id}/opening-status`,
            {},
            {
              params: {
                status: "closed",
              },
            },
          );
          if (res.status === 200) {
            message.success("Operation successful.");
            setTimeout(() => {
              refetch();
            }, 500);
            setIsSetCloseModalOpen(false);
          } else message.error("Operation fail.");
        }}
        onCancel={() => {
          setIsSetCloseModalOpen(false);
        }}
      />

      <JobSetOpenModal // @ts-ignore
        open={isSetOpenModalOpen} // @ts-ignore
        onOk={async () => {
          try {
            const res = await axiosInstance.put(
              `/jobs/${job.id}/opening-status`,
              {},
              {
                params: {
                  status: "open",
                },
              },
            );
            if (res.status === 200) {
              message.success("Operation successful.");
              setTimeout(() => {
                refetch();
              }, 500);
              setIsSetOpenModalOpen(false);
            } else message.error("Operation fail.");
          } catch (error) {
            console.error(error);
          }
        }}
        onCancel={() => {
          setIsSetOpenModalOpen(false);
        }}
      />

      <div
        className={`${
          mode === "light" ? "bg-white" : "bg-white bg-opacity-10"
        } p-4 mb-10`}
      >
        <Tabs
          className="sticky top-0 bg-white z-10"
          tabBarExtraContent={operations}
          defaultActiveKey="info"
          onChange={(key) => setActiveTab(key)}
        >
          <Tabs.TabPane tab="Job Information" key="info" />
          {isCompanyRole && <Tabs.TabPane tab="Received" key="received" />}
        </Tabs>

        {renderInfoTab()}
        {renderReceivedTab()}
      </div>
    </>
  );
};
