import { PaymentMethodValueType } from "@/components/businessComponents";
import { ApplicationStatEnum } from "@/pages/Applications/enum";

export interface GetJobDetailResponse {
  id: string;
  title: string;
  company_id: string;
  company_logo_url: string;
  company_shortname: string;
  company_scale: string;
  company_industry: string;
  company_country: string;
  company_business_fields: string[];
  project_id: string;
  description: string; // Skills and Requirement
  software: string[];
  location: string;
  remote_option: string;
  employment_type: string;
  group_id: string;
  user_id: string;

  test: {
    link: string;
    pic_url: string;
    video_url: string;
    description: string;
  };
  benchmark: {
    link: string;
    pic_url: string;
    video_url: string;
    description: string;
  };

  is_enabled: number;
  created_at: string;
  department_description: string;

  is_project_verified: number;
  project_name: string;
  project_thumbnail_url: string;
  project_description: string;
  project_start_date: string;
  project_end_date: string;
  progress_status: string;

  standards: {
    link: string;
    pic_url: string;
    video_url: string;
    description: string;
  }[];

  shortname: string;
  industry: string;
  scale: string;
  logo_url: string;
  is_company_verified: number;
  focus_areas: string[];

  // budget
  headcount: number;
  payment_method: string;
  count: number;
  count_unit: string;
  rate: number;
  rate_unit: string;

  // stats
  total_views: number;
  total_applicants: number;
  total_interviewed: number;
  total_communicated: number;
}

type ExtraProps = {
  company_shortname: string;
  is_company_verified: number;
  project_thumbnail_url: string;
  title: string;
  id: string;
};

export enum PaymentStatusType {
  PENDING = "PENDING",
  UNPAID = "UNPAID",
  PAID = "PAID",
  PROCESSING = "PROCESSING",
}

export type ApplicantCardProps = {
  company_logo_url: string;
  job_title: string;
  job_id: string;
  project_name: string;
  project_id: string;
  project_thumbnail_url: string;
  company_id: string;
  company_name: string;
  company_industry: string;
  company_scale: string;
  industry: string;
  introduction: string | null;
  city: string | null;
  location: string;
  remote_option: string;
  employment_type: string;
  headcount: number;
  payment_method: PaymentMethodValueType;
  party_b_email: string;
  count: number;
  count_unit: string;
  rate: number;
  rate_unit: string;
  answer_url: string | null;
  answer_link: string | null;
  created_at: string;
  id: string;
  status: ApplicationStatEnum;
  rejection_reason: string;
  total_cost: number;
  currency: string;
  role: string;
  user_name: string;
  user_id: string;
  avatar_url: string | null;
  remark: string | null;
  company_shortname?: string;
  is_job_available?: number;
  is_company_verified?: boolean;
  is_project_verified?: boolean;
  memo_remark: string | null;
  is_saved_for_later: boolean;

  contract: {
    id: string;
    party_a_name: string;
    party_b_name: string;
    party_a_email: string;
    party_b_email: string;
    headcount: number | null;
    start_date: string | null;
    end_date: string | null;
    payment_method: PaymentMethodValueType;
    payment_status: PaymentStatusType;
    count: number | null;
    count_unit: string | null;
    rate: number | null;
    total_cost: number | null;
    deposit: number | null;
    service_fee_rate: number;
    service_fee: number;
    receive_amount: number;
    settle_day: number | null;
    timezone: string;
    currency: string;
    is_deposit_required: boolean;
    deposit_due_date: string;
    cancelled_at: string | null;
    is_party_a_verified: number | null;
    is_party_b_verified: number | null;
    rejection_reason: string | null;
    project_file_urls: string[] | null;
    delivery_and_payment_schedule_file_urls: string[] | null;
    contract_file_urls: string[] | null;
    signed_at: string | null;
    deposit_pay_time: string | null;
  } | null;

  // added by combining API results
  extra: ExtraProps;
};
