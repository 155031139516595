import { CustomCheckbox } from "@/components/customComponents";
import UploadOrInput from "@/pages/enterprises/components/UploadOrInput";
import {
  Checkbox,
  Divider,
  Form,
  FormInstance,
  Image,
  InputNumber,
  Switch,
} from "antd";
import { FC, ReactNode, useEffect, useState } from "react";

interface Props {
  form: FormInstance;
}

const Step3: FC<Props> = ({ form }) => {
  useEffect(() => {
    form.setFieldsValue({
      testType: "upload",
    });
  }, [form]);

  return (
    <div>
      <Form
        form={form}
        initialValues={{ is_visible_to_public: true }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <UploadOrInput
          typeFieldName="testType"
          typeFieldLabel="Test"
          uploadProps={{
            // accept: "image/*,video/*",
            limitSize: 10,
            limitDesc: "The file should not exceed 10MB.",
          }}
          fileFieldName="testFile"
          linkFieldName="testLink"
        />
        <Form.Item
          label="Application visible to Public"
          name="is_visible_to_public"
          required
          extra="Your application is set to be publicly visible by default. If you do not wish to display your application to the public, please turn off this option."
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label=""
          wrapperCol={{ offset: 6, span: 14 }}
          name="isAgree"
          valuePropName="checked"
          className="!mb-0 ml-[-34px]"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Please carefully read the above content and agree.",
                      ),
                    ),
            },
          ]}
        >
          <CustomCheckbox className="text-[#999] !items-start">
            By uploading content onto the website, you confirm that no
            third-party Intellectual Property rights have been violated by your
            actions. Furthermore, you acknowledge and agree that you are solely
            responsible for any and all infringements or disputes that may arise
            in relation to the uploaded content and agree to indemnify Leyline
            Technologies, Inc. against any claims or damages resulting from such
            infringements or disputes.
          </CustomCheckbox>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step3;
