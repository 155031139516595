import {
  CustomDropDown,
  CustomEmpty,
  CustomInput,
  CustomSegmented,
  CustomSelect,
  CustomTabs,
} from "@/components/customComponents";
import LeyIcon from "@/components/leylineIcons";
import CardItem from "@/pages/Applications/components/CardItem";
import {
  JobItem,
  ReceGroupEnum,
  applicationSorts,
  renderApplicaStageOptions,
  renderToSignFilters,
} from "@/pages/Applications/utils";
import JobDetail from "@/pages/enterprises/components/JobDetail";
import { ApplicantCardProps } from "@/pages/jobs/type";
import eventBus from "@/utils/eventBus";
import { SearchOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Grid, Result, Row, theme } from "antd";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  item: JobItem;
  hideJobTitle?: boolean;
  fnRefresh: (e?: {
    job_id?: string;
    project_id?: string;
    published_list_status?: ReceGroupEnum;
    sub_status?: number;
    sort?: string;
  }) => void;
}

const { useToken } = theme;

const JobGroup: FC<Props> = ({ item, hideJobTitle = false, fnRefresh }) => {
  const [stageVal, setStageVal] = useState(ReceGroupEnum.PENDING);
  const [filterVal, setFilterVal] = useState("");
  const [sortVal, setSortVal] = useState("LATEST_APPLICATION");
  const [jobDetailVisible, setJobDetailVisible] = useState(false);
  const [keywords, setKeywords] = useState("");
  const navigate = useNavigate();
  const breakpoint = Grid.useBreakpoint();
  const { token } = useToken();

  useEffect(() => {
    if (stageVal === ReceGroupEnum.TO_SIGN) setFilterVal("T0");
    else if (stageVal === ReceGroupEnum.SIGNED) setFilterVal("S0");
    else setFilterVal("");
  }, [stageVal]);

  useEffect(() => {
    const cb = () => setStageVal(ReceGroupEnum.PENDING);
    eventBus.subscribe("resetStageVal", cb);
    return () => eventBus.unsubscribe("resetStageVal", cb);
  }, []);

  const showSubTabs = useMemo(() => {
    return [ReceGroupEnum.TO_SIGN, ReceGroupEnum.SIGNED].includes(stageVal);
  }, [stageVal]);

  const filterApplications = useMemo(() => {
    return keywords
      ? item.applications.filter((x) =>
          x.role === "Company"
            ? x.company_name.toUpperCase().includes(keywords.toUpperCase()) ||
              x.company_shortname
                ?.toUpperCase()
                ?.includes(keywords.toUpperCase())
            : x.user_name.toUpperCase().includes(keywords.toUpperCase()),
        )
      : item.applications;
  }, [item.applications, keywords]);

  const refreshData = useCallback(
    () =>
      fnRefresh({
        job_id: item.job_id,
        published_list_status: stageVal,
        sub_status: filterVal
          ? parseFloat(filterVal.replace(/[A-Z]/, ""))
          : undefined,
        sort: sortVal,
      }),
    [item.job_id, stageVal, filterVal, sortVal, fnRefresh],
  );

  useEffect(() => {
    const channel = new BroadcastChannel("refreshReceivedList");
    channel.onmessage = () => {
      const KEY = "refreshJobId";
      const localJobId = localStorage.getItem(KEY);
      if (localJobId === item.job_id) {
        refreshData();
        localStorage.removeItem(KEY);
      }
    };
    return () => channel.close();
  }, [item.job_id, refreshData]);

  const onClickJob = () => {
    setJobDetailVisible(true);
  };

  const sortItems = useMemo(() => {
    return applicationSorts.map((x) => ({
      key: x.value,
      label: x.label,
      onClick: () => {
        setSortVal(x.value);
        fnRefresh({
          job_id: item.job_id,
          published_list_status: stageVal,
          sub_status: filterVal
            ? parseFloat(filterVal.replace(/[A-Z]/, ""))
            : undefined,
          sort: x.value,
        });
      },
    }));
  }, [fnRefresh, filterVal, stageVal, item.job_id]);

  const InputEl = (
    <CustomInput
      allowClear
      value={keywords}
      size="large"
      className="max-w-[330px] mr-5"
      roundRadius
      lightBg
      placeholder="Please enter applicant name..."
      prefix={<SearchOutlined style={{ color: token.colorTextPlaceholder }} />}
      onChange={(e) => setKeywords(e.target.value)}
    />
  );
  const SortCondEl = (
    <CustomDropDown menu={{ items: sortItems }} trigger={["hover"]}>
      <div className="bg-white/[.1] text-2xl text-white py-1 px-2 text-center rounded cursor-pointer hover:bg-white/[.2]">
        <LeyIcon icon="Sort" />
      </div>
    </CustomDropDown>
    // <CustomSelect
    //   roundRadius
    //   lightBg
    //   size="large"
    //   value={sortVal}
    //   onChange={(e) => {
    //     setSortVal(e);
    //     fnRefresh({
    //       job_id: item.job_id,
    //       published_list_status: stageVal,
    //       sub_status: filterVal
    //         ? parseFloat(filterVal.replace(/[A-Z]/, ""))
    //         : undefined,
    //       sort: e,
    //     });
    //   }}
    //   options={applicationSorts}
    // />
  );

  return (
    <div key={item.job_id} className="bg-[#2A2D30] mb-6 p-7 rounded-xl">
      {!hideJobTitle && (
        <div className="text-[#00ADAE] text-2xl mb-5">
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <span
            className=" cursor-pointer hover:underline"
            onClick={onClickJob}
          >
            {item.project_name} / {item.job_title}
          </span>
        </div>
      )}
      <div className={`flex ${showSubTabs ? "mb-4" : "mb-7"}`}>
        <CustomSegmented
          className="mr-5"
          size="large"
          value={stageVal}
          onChange={(val) => {
            setStageVal(val as ReceGroupEnum);
            fnRefresh({
              job_id: item.job_id,
              published_list_status: val as ReceGroupEnum,
              sub_status: undefined,
              sort: sortVal,
            });
          }}
          options={renderApplicaStageOptions(item)}
        />

        {breakpoint.xxl && (
          <>
            {InputEl}
            {SortCondEl}
          </>
        )}
      </div>
      <div className="flex">
        {showSubTabs && (
          <CustomTabs
            className="mr-5"
            items={renderToSignFilters(stageVal, item)}
            activeKey={filterVal}
            onChange={(e) => {
              setFilterVal(e);
              fnRefresh({
                job_id: item.job_id,
                published_list_status: stageVal,
                sub_status: e ? parseFloat(e.replace(/[A-Z]/, "")) : undefined,
                sort: sortVal,
              });
            }}
          />
        )}
        {!breakpoint.xxl && (
          <div className="mb-4 flex">
            {InputEl}
            {SortCondEl}
          </div>
        )}
      </div>
      <Row gutter={[24, 24]}>
        {/* @ts-ignore */}
        {filterApplications.length > 0 ? (
          filterApplications.map(
            (x: ApplicantCardProps | undefined, i: number) => (
              <Col span={8} key={x?.id}>
                <CardItem
                  type="received"
                  infoData={x}
                  onClickContainer={() => {}}
                  refresh={refreshData}
                />
              </Col>
            ),
          )
        ) : (
          <CustomEmpty className="mx-auto mt-[40px]" />
        )}
      </Row>
      <Drawer
        width={"75%"}
        open={jobDetailVisible}
        onClose={() => setJobDetailVisible(false)}
      >
        <JobDetail
          id={item.job_id as string}
          onClose={() => setJobDetailVisible(false)}
        />
      </Drawer>
    </div>
  );
};

export default JobGroup;
