import { getInvitationCode } from "@/components/header/services";
import { getStaticImgUrl } from "@/utils/common";
import {
  CheckOutlined,
  CopyOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { useParsed } from "@refinedev/core";
import { useEventListener } from "ahooks";
import { Image, Modal, Typography, message } from "antd";
import { FC, useEffect, useState } from "react";
import giftImg from "/images/gift.png";
import styles from "../index.module.css";

const { Text } = Typography;

const Promotions: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invitationCode, setInvitationCode] = useState("");
  const [baseFontSize, setBaseFontSize] = useState(
    (window.innerWidth / 1920) * 16,
  );
  const { pathname } = useParsed();

  useEventListener("resize", () => {
    setBaseFontSize((window.innerWidth / 1920) * 16);
  });

  useEffect(() => {
    const confShow = localStorage.getItem("showPromotions");
    if (confShow !== "true" && !isModalOpen) return;
    getInvitationCode().then((res) => {
      if (res.status === 200) {
        setInvitationCode(res.data.invitation_code);
        if (pathname === "/") {
          setIsModalOpen(true);
          localStorage.setItem("showPromotions", "false");
        }
      }
    });
  }, [pathname, isModalOpen]);

  const fnShowPromotions = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fnCopy = (type: string) => {
    navigator.clipboard
      .writeText(
        type === "link"
          ? `${window.location.origin}/mktp/register?inviCode=${
              invitationCode || ""
            }`
          : invitationCode,
      )
      .then(() => {
        message.success(`The invitation ${type} copied to clipboard`);
      })
      .catch((err) => {
        message.error("Failed to copy the link");
        console.error(err);
      });
  };

  return (
    <>
      <Image
        className={`${styles.giftRun} cursor-pointer`}
        src={giftImg}
        preview={false}
        width={62}
        height={62}
        onClick={fnShowPromotions}
      />
      <Modal
        title=""
        open={isModalOpen}
        footer={null}
        width={"30vw"}
        onCancel={handleCancel}
        className={styles.modalContent}
        styles={{
          content: {
            position: "relative",
            height: "37.5vw",
            background: `url(${getStaticImgUrl(
              "promotionBg.png?a=1",
            )}) left top / cover no-repeat`,
          },
        }}
      >
        <div className="absolute top-[19.4vw] left-[33.5%] text-center text-[#FE4161]">
          <strong
            className="uppercase mr-1"
            style={{ fontSize: `${baseFontSize + 2}px` }}
          >
            {invitationCode}
          </strong>
          <CopyOutlined
            className="text-[#FE4161]"
            style={{ fontSize: `${baseFontSize}px` }}
            onClick={() => fnCopy("code")}
          />
          <div
            className="text-[#3961F2] cursor-pointer underline"
            onClick={() => fnCopy("link")}
            onKeyUp={() => {}}
            style={{ fontSize: `${baseFontSize - 1}px` }}
          >
            Copy Invitation Link
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Promotions;
