import {
  CustomCascader,
  CustomCheckbox,
  CustomEmpty,
} from "@/components/customComponents";
import { JobItem, ReceGroupEnum } from "@/pages/Applications/utils";
// import { ApplicantInfo } from "@/components/users/ArtistApplicantCard";
import { getReceivedApplications } from "@/pages/users/services";
import eventBus from "@/utils/eventBus";
// import { useGlobalStore } from "@/stores";
import { useInfiniteScroll, useRequest } from "ahooks";
import { CascaderProps, Spin, message } from "antd";
import { GetProp } from "antd/lib";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import JobGroup from "./components/JobGroup";
import useJobsOptions from "./hooks/useJobsOptions";

type DefaultOptionType = GetProp<CascaderProps, "options">[number];
const PAGE_SIZE = 100000;

const ApplicationsReceived: FC = () => {
  const navigate = useNavigate();
  // const { currentRole } = useGlobalStore((state) => state);
  const [applicationsData, setApplicationsData] = useState<JobItem[]>([]);
  const [onlyOpen, setOnlyOpen] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const curActionInPageRef = useRef(1);
  const searchValsRef = useRef<string[] | undefined>(undefined);
  const searchValChanged = useRef(false);
  const filterOptions = useJobsOptions();

  const applicationsDataRef = useRef<JobItem[]>([]);
  applicationsDataRef.current = applicationsData;

  const {
    loading,
    run: fetchItems,
    params,
  } = useRequest(
    (item?: {
      job_id?: string;
      only_open?: boolean;
      published_list_status?: ReceGroupEnum;
      sub_status?: number;
      project_id?: string;
      sort?: string;
    }) => {
      const {
        job_id,
        only_open,
        published_list_status,
        sub_status,
        project_id,
        sort,
      } = item || {};
      return getReceivedApplications({
        job_id,
        published_list_status,
        sub_status,
        project_id,
        only_open: only_open ?? onlyOpen,
        sort,
      });
    },
    {
      manual: true,
      onSuccess(res) {
        const { job_id, published_list_status } = params[0] || {};
        if (job_id && published_list_status) {
          const replaceIndex = applicationsDataRef.current.findIndex(
            (item) => item.job_id === job_id,
          );
          applicationsDataRef.current.splice(
            replaceIndex,
            1,
            res.data.job_application_items.length > 0
              ? res.data.job_application_items[0]
              : { job_id, applications: [] },
          );
          setApplicationsData([...applicationsDataRef.current]);
        } else {
          setApplicationsData(res.data.job_application_items);
        }
      },
      onError(e) {
        message.error(
          // @ts-ignore
          e?.errors?.detail ||
            "An unknown error occurred. Please try again later.",
        );
      },
    },
  );

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const fnFilterJob = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1,
    );

  // const {
  //   data: applicationsData,
  //   loading,
  //   loadingMore,
  //   noMore,
  //   mutate,
  //   error: isErrorApplica,
  // } = useInfiniteScroll((d) => (d ? fetchItems(d?.nextPage) : fetchItems(1)), {
  //   target: ref,
  //   isNoMore: (d) => !d?.nextPage,
  //   reloadDeps: [fetchItems],
  // });

  // const handledApplicationsData = useMemo(() => {
  //   // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  //   const arr: any[] = [];
  //   const obj: { [key: string]: string } = {};
  //   if (!applicationsData?.list) return arr;
  //   // biome-ignore lint/complexity/noForEach: <explanation>
  //   applicationsData.list.forEach((x) => {
  //     if (!obj[x.job_id]) {
  //       obj[x.job_id] = "1";
  //       arr.push({
  //         project_name: x.project_name,
  //         job_name: x.job_title,
  //         job_id: x.job_id,
  //         list: [x],
  //       });
  //     } else {
  //       const res = arr.find((v) => v.job_id === x.job_id);
  //       res.list.push(x);
  //     }
  //   });
  //   return arr;
  // }, [applicationsData?.list]);

  // const fnRefresh = useCallback(async () => {
  //   const curPageNewData = await fetchItems(curActionInPageRef.current);
  //   mutate((previousData) => {
  //     const updatedList = [...(previousData?.list ?? [])];
  //     const curPageStartIndex = (curActionInPageRef.current - 1) * PAGE_SIZE;
  //     updatedList.splice(curPageStartIndex, PAGE_SIZE, ...curPageNewData.list);
  //     return {
  //       ...previousData,
  //       list: updatedList,
  //     };
  //   });
  // }, [fetchItems, mutate]);

  // const handleCurActionInPage = (index: number) => {
  //   const curPage = Math.ceil((index + 1) / PAGE_SIZE);
  //   curActionInPageRef.current = curPage;
  // };

  return (
    <Spin spinning={loading} className="!max-h-[70vh]">
      <div className="p-[30px] pr-6 min-h-[calc(100vh-80px)]">
        <div className="flex items-center mb-7">
          <CustomCascader
            roundRadius
            size="large"
            placeholder="Select Project、Job"
            className="w-96 mr-5"
            changeOnSelect
            // value={[]}
            showSearch={{ filter: fnFilterJob }}
            options={filterOptions}
            onChange={(e) => {
              searchValsRef.current = e as string[];
              searchValChanged.current = true;
            }}
            onClear={() => {
              fetchItems();
              eventBus.publish("resetStageVal");
            }}
            onDropdownVisibleChange={(visible) => {
              if (!visible && searchValChanged.current) {
                if (searchValsRef.current) {
                  const [pId, jId] = searchValsRef.current;
                  fetchItems({
                    job_id: jId as string,
                    project_id: pId as string,
                    published_list_status: undefined,
                    sub_status: undefined,
                    sort: undefined,
                  });
                } else {
                  fetchItems();
                }
                eventBus.publish("resetStageVal");
              }
              searchValChanged.current = false;
            }}
          />
          <CustomCheckbox
            checked={onlyOpen}
            onChange={(e) => {
              const val = e.target.checked;
              setOnlyOpen(val);
              if (searchValsRef.current) {
                const [pId, jId] = searchValsRef.current;
                fetchItems({
                  only_open: val,
                  job_id: jId as string,
                  project_id: pId as string,
                });
              } else {
                fetchItems({ only_open: val });
              }
              eventBus.publish("resetStageVal");
            }}
          >
            View open Jobs
          </CustomCheckbox>
        </div>
        <div className="relative">
          {/* @ts-ignore */}
          {applicationsData?.length > 0 ? (
            applicationsData?.map((item) => (
              <JobGroup key={item.job_id} item={item} fnRefresh={fetchItems} />
            ))
          ) : (
            <CustomEmpty className="mx-auto mt-[140px]" />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default ApplicationsReceived;
