import { CustomLink } from "@/components/customComponents";
import { TERMS_CONDITIONS_URL } from "@/constants";
import { Checkbox, DatePicker, Form, FormInstance } from "antd";
import { FC } from "react";

interface Props {
  form: FormInstance;
}

const dotClass =
  "before:absolute before:left-[-20px] before:top-[9px] before:w-[5px] before:h-[5px] before:bg-[#00ADAE] before:rounded-full before:content-[''] before:align-middle before:mr-[10px]";

const Step4: FC<Props> = ({ form }) => {
  return (
    <div className="text-[#999]">
      <div className="px-32 mb-[108px]">
        <div className={`relative ${dotClass}`}>
          By checking this box, you acknowledge you have read and understood all{" "}
          <CustomLink href={TERMS_CONDITIONS_URL}>
            terms and conditions
          </CustomLink>{" "}
          presented herein. You further acknowledge that you are solely
          responsible for ensuring your compliance with these terms and
          conditions. Failure to do so may result in consequences for which you
          will bear full responsibility.
        </div>
      </div>
      <Form form={form}>
        <Form.Item
          label=""
          name="isAgree"
          valuePropName="checked"
          className="text-center !mb-0"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Please carefully read the above content and agree.",
                      ),
                    ),
            },
          ]}
        >
          <Checkbox className="text-[#999]">Yes, I understand.</Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step4;
